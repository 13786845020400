<template>
  <!-- 分享弹窗 -->
  <el-dialog v-model="shareWindow" :lock-scroll="false" :width="'500px'">
    <div v-if="!shareCancel">
      <div class="share-name">
        发送给:{{shareName}}
      </div>
      <div class="share-infos">
        <div v-if="shareInfos.type == 10030" class="words">
          [链接]：{{shareInfos.title}}
        </div>
        <div v-if="shareInfos.type == 10031" class="words">
          [教授主页]:{{shareInfos.name}}
        </div>
        <div v-if="shareInfos.type == 10032" class="words">
          [链接]:{{shareInfos.title}}
        </div>
      </div>
      <div class="share-buttons">
        <el-button @click="sendShareMessage(shareInfos.type,shareId)" class="confirm">发送</el-button>
        <el-button @click="shareCancel = true">取 消</el-button>
      </div>
    </div>
    <!-- 取消分享 -->
    <div v-else>
      <div class="share-name">
        是否取消分享？
      </div>
      <div class="share-buttons">
        <el-button @click="cancelShare" class="confirm">确定取消</el-button>
        <el-button @click="changeShare">修改分享对象</el-button>
        <el-button @click="backToShare">返回分享</el-button>
      </div>
    </div>
  </el-dialog>
  <div v-if="showBusinessWindow">
    <!-- 群组商学院弹窗 -->
    <BusinessWindow @changeWindow="changeWindow"></BusinessWindow>
  </div>
  <div class="im-container" v-if="imWindowVisible && !notShowWindow" id="imContainer" v-drag>
    <div class="im-left-bar" @click="closeGroupTool">
      <div class="im-self-info" id="selfInfo">
        <img class="img-avatar" :src="staticPath + (TanInfo.headImage || '/defaultlogo.png')" v-if="isTan"/>
        <img class="img-avatar" :src="staticPath + avatar" v-else/>
        <div class="im-my-name" v-if="isTan">{{ TanInfo.name }}</div>
        <div class="im-my-name" v-else>{{ name }}</div>
        <img v-if="isVip" class="vipIcon" src="@/assets/images/icon-vip.png" />
      </div>
      <div class="friend-list" id="friendList">
        <!-- 系统消息 -->
        <div @click="switchToFriend('system')"
              :class="{'friend-card-s': currentFriendId == 'system'}"
              class="friend-card system-back" style="min-height: 70px;">
          <div class="friend-avatar">
            <img src="@/assets/images/im/system-message.png" />
            <el-badge v-if="systemMessageNum > 0" class="badgePosition" :value="systemMessageNum" :max="99" type="danger"></el-badge>
          </div>
          <div class="friend-text">
            <div class="friend-info">
              <div class="friend-name">系统消息</div>
            </div>
            <div class="latest-message" v-if="systemMessages.length > 0">
              <div class="latest-message-content">系统消息</div>
              <div class="latest-message-time">{{dateFormatToComplete(new Date(systemMessages[systemMessages.length - 1].sendTime))}}</div>
            </div>
          </div>
        </div>
        <div style="padding: 10px" v-if="updating">
          <el-skeleton :row="5" animated />
        </div>
        <div v-else>
          <!-- 聊天列表 -->
          <div @click="switchToFriend(friend.memberId)" v-for="(friend,i) in friends" :key="i" :class="{'friend-card-s': friend.memberId == currentFriendId}" class="friend-card">
            <div class="friend-avatar">
              <img v-if="friend?.headImage" :src="staticPath + friend?.headImage" />
              <img v-if="!friend.headImage && friend.userProfile?.avatar"  :src="friend.userProfile.avatar === 'defaultlogo.png' ? require('../../assets/images/square-avatar.png') : userHeadImage(friend.userProfile)"/>
              <img v-else-if="!friend.headImage && friend.groupProfile?.avatar"  :src="friend.groupProfile.avatar === 'defaultlogo.png' ? require('../../assets/images/square-avatar.png') : userHeadImage(friend.groupProfile)"/>
              <img v-else :src="require('../../assets/images/square-avatar.png')" />
              <el-badge v-if="friend.unread_num > 0" class="badgePosition" :value="friend.unread_num" :max="99" type="danger"></el-badge>
            </div>
            <div class="friend-text">
              <div class="friend-info">
                <div class="friend-name">{{friend.name || friend.userProfile?.nick || friend.groupProfile?.name}}</div>
                <div class="friend-labels">
                  <span v-if="friend.positionName">{{friend.positionName}}</span>
                  <span v-if="friend.educationName">{{friend.educationName}}</span>
                </div>
              </div>
              <!-- 无需VIP看最后一条消息 -->
              <div class="latest-message">
                <!-- 接口数据 -->
                <div v-if="friend.msgType || friend.msgType===0">
                  <div class="latest-message-content" v-if="friend.msgType == 0">{{friend.lastMsg}}</div>
                  <div class="latest-message-content" v-else>{{strToObj(friend.lastMsg).des}}</div>
                  <div class="latest-message-time">{{friend.lastContactTime}}</div>
                </div>
                <!-- 腾讯云数据 -->
                <div v-if="friend.lastMessage && friend.lastMessage.type">
                  <!-- 1.7 路演邀请链接的最新消息 -->
                  <div v-if="friend.lastMessage.type === 'TIMTextElem'" class="latest-message-content" v-html="changeLastMessage(friend.lastMessage.payload.text,friend)">
                  </div>
                  <div v-if="friend.lastMessage.type === 'TIMFileElem'" class="latest-message-content">[文件]</div>
                  <div v-if="friend.lastMessage.type === 'TIMImageElem'" class="latest-message-content">[图片]</div>
                  <div v-if="friend.lastMessage.type === 'TIMVideoFileElem'" class="latest-message-content">[视频]</div>
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' && friend.lastMessage.payload?.extension === '10000'" class="latest-message-content">
                    需求联系：{{strToObj(friend.lastMessage.payload.data).des}}
                  </div>
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' &&((friend.lastMessage.payload.extension == '20020') || strToObj(friend.lastMessage.payload?.data)?.type == 20020)" class="latest-message-content">
                    路演链接
                  </div>
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' && friend.lastMessage.payload?.extension === '10001'" class="latest-message-content">
                    {{strToObj(friend.lastMessage.payload.data).des}}
                  </div>
                  <!-- 从type获取会议类型 -->
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' && strToObj(friend.lastMessage.payload.data).type == '10009'" class="latest-message-content">
                    {{strToObj(friend.lastMessage.payload.data).des.startsWith('语音') ? strToObj(friend.lastMessage.payload.data).des : '语音' + strToObj(friend.lastMessage.payload.data).des}}
                  </div>
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' && strToObj(friend.lastMessage.payload.data).type == '10010'" class="latest-message-content">
                    {{strToObj(friend.lastMessage.payload.data).des.startsWith('视频') ? strToObj(friend.lastMessage.payload.data).des : '视频' + strToObj(friend.lastMessage.payload.data).des}}
                  </div>
                  <!-- 分享消息 -->
                  <div v-if="friend.lastMessage.type === 'TIMCustomElem' && (strToObj(friend.lastMessage.payload.data).type == '10030' || strToObj(friend.lastMessage.payload.data).type == '10031' || strToObj(friend.lastMessage.payload.data).type == '10032')" class="latest-message-content">
                    分享消息
                </div>
                  <div v-if="friend.lastMessage.type === 'TIMSoundElem'">[语音消息]</div>
                </div>
                <div v-if="friend.messageType || friend.lastMessage" class="latest-message-time">{{friend.messageType ? dateFormatToComplete(new Date(friend.lastContactTime)) : dateFormatToComplete(new Date(friend.lastMessage.lastTime*1000))}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="im-right-bar" v-if="isSdkdata" @click="closeGroupTool">
      <!-- 名字 -->
      <div class="title-bar" id="titleBar">
        <!-- 聊天窗口 -->
        <div class="current-title" v-if="currentFriendId">
          <div class="current-friend-avatar" v-if="currentFriendId && currentFriendId !== 'system'">
            <img v-if="currentFriend.headImage" :src="staticPath + currentFriend.headImage" />
            <img v-if="!currentFriend.headImage && currentFriend.userProfile?.avatar"  :src="currentFriend.userProfile.avatar === 'defaultlogo.png' ? require('../../assets/images/square-avatar.png') : userHeadImage(currentFriend.userProfile)"/>
            <img v-else-if="!currentFriend.headImage && currentFriend.groupProfile.avatar"  :src="currentFriend.groupProfile.avatar === 'defaultlogo.png' ? require('../../assets/images/square-avatar.png') : userHeadImage(currentFriend.groupProfile)"/>
            <img v-else :src="require('../../assets/images/square-avatar.png')" />
          </div>
          <div class="current-friend-avatar" v-else-if="currentFriendId === 'system'">
            <img src="../../assets/images/im/system-message.png" alt="">
          </div>
          <div class="current-friend-name" v-if="currentFriendId !== 'system'">{{ currentFriend ? (currentFriend.name || currentFriend.userProfile?.nick || currentFriend.groupProfile?.name) : "" }}</div>
          <div v-else>系统消息</div>
          <el-tag type="info" v-if="currentFriendId && !currentFriendStatus && currentFriendId !== 'system' && currentFriend?.type !== 'GROUP'">离线</el-tag>
          <el-tag type="success" v-if="currentFriendId && currentFriendStatus && currentFriendId !== 'system' && currentFriend?.type !== 'GROUP'">在线</el-tag>
        </div>
        <div v-else></div>
        <div>
          <el-icon @click.stop="showGroupTool = true" class="el-icon-more close-button" style="color: #666666;margin-right: 20px;" v-if="isGroup"></el-icon>
          <el-icon @click="hideWindow()" class="el-icon-circle-close close-button" style="color: #666666"></el-icon>
        </div>
      </div>
      <!-- 聊天内容 -->
      <div @dragover="fileDragover" @drop="fileDrop" ref="messageBlock" class="message-block" v-if="currentFriendId">
        <!-- 上拉加载 -->
        <div @click="seeMore" class="shangla" v-if="currentFriendId !== 'system' && !currentFriend.isCompleted">
          <img src="@/assets/images/shangla.png" alt="">
          <span>查看更多</span>
        </div>
        <!-- 系统消息 -->
        <div @click="seeMoreSystem" class="shangla" v-if="currentFriendId === 'system' && total !== systemMessages.length">
          <img src="@/assets/images/shangla.png" alt="">
          <span>查看更多</span>
        </div>
        <div v-if="currentFriendId !== 'system' && currentFriend.messages && currentFriend.messages.length > 0">
          <div class="message-line" v-for="(message, i) in currentFriend.messages" :key="i"
               :class="{
                'others-message':
                  (message.from == currentFriendId && message.conversationType !== 'GROUP') ||
                  (message.conversationType === 'GROUP' && message.from != myId),
                'my-message':
                  (message.from != currentFriendId && message.conversationType !== 'GROUP') ||
                  (message.conversationType === 'GROUP' && message.from == myId)}">
            <!-- 消息时间 -->
            <div class="message-time-line" v-if="showTime(i)">
              {{formatFullTime((message.time)*1000)}}
            </div>
            <!-- 群系统消息 -->
            <div class="message-group-system-line" :class="{'p-t-30': showTime(i)}" v-if="message.type === 'TIMGroupTipElem' && message.from === '@TIM#SYSTEM'">
              <p v-if="message.payload?.operationType === 1">"{{ message.payload?.oName || '...' }}"邀请"<span>{{ message.nick }}</span>"加入群组</p>
              <p v-else-if="message.payload?.operationType === 3">"{{ message.payload?.oName || '...' }}"将"<span>{{ message.nick }}</span>"踢出群组</p>
            </div>
            <!-- 自定义内容 -->
            <div class="custom-wrap" v-if="message.type === 'TIMCustomElem' && message.payload.extension === '10000'" :class="{'margin-time': showTime(i)}">
              <div class="custom">{{strToObj(message.payload.data).des}}</div>
            </div>
            <!-- 自定义内容 extension记录信息 -->
            <div class="custom-wrap" v-else-if="message.type === 'TIMCustomElem' && message.payload.data?.length === 0 && strToObj(message.payload.extension).type === 10000 " :class="{'margin-time': showTime(i)}">
              <div class="custom">{{strToObj(message.payload.extension).des}}</div>
            </div>
            <div class="message-avatar" v-if="(message.type !== 'TIMCustomElem'  ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '20020') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10032') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10031') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10030') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10001') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10009') ||
                  (message.type == 'TIMCustomElem' && message.payload.extension === '10010') ||
                  (message.type == 'TIMCustomElem' && message.payload.data && strToObj(strToObj(message.payload.data).data).call_type)) && !(message.type === 'TIMGroupTipElem' && message.from === '@TIM#SYSTEM')" :class="{'margin-time': showTime(i)}" @click="toSpace(message)">
              <img v-if="message.from == currentFriendId && message.conversationType !== 'GROUP'" :src="message.avatar ? msgHeadImage(message) : require('../../assets/images/square-avatar.png')" />
              <img v-else-if="message.from != myId && message.conversationType === 'GROUP'"
                :src="!message.avatar?.includes('null') ? message.avatar : require('../../assets/images/square-avatar.png')" />
              <!-- 当前用户的头像是直接获取的无需操作 -->
              <img v-else :src="staticPath + avatar" />
            </div>
            <!-- extension为JSON字符串 自定义内容 10001 头像 -->
            <div class="message-avatar" v-else-if="message.type === 'TIMCustomElem' && message.payload.data.length === 0 && strToObj(message.payload.extension).type === 10001" :class="{'margin-time': showTime(i)}">
              <img v-if="message.from == currentFriendId" :src="message.avatar ? message.avatar : require('../../assets/images/square-avatar.png')" />
              <img v-else :src="staticPath + avatar" />
            </div>
            <div class="message-container"
              v-if="(message.type !== 'TIMCustomElem' ||
                    (message.type == 'TIMCustomElem' && message.payload.extension === '10001') ||
                    (message.type == 'TIMCustomElem' && message.payload.extension === '10009') ||
                    (message.type == 'TIMCustomElem' && message.payload.extension === '10010') ||
                    (message.type == 'TIMCustomElem' && message.payload.data && strToObj(strToObj(message.payload.data).data).call_type)) && !(message.type === 'TIMGroupTipElem' && message.from === '@TIM#SYSTEM')" :class="{'margin-time': showTime(i)}">
              <div class="message-text" v-if="message.type">
                <!-- 路演邀请链接 -->
                <div v-if="message.type==='TIMTextElem'" class="text">
                  <div v-if="!checkLiveLink(message)" v-html="changeEmoji(message.payload.text)">
                  </div>
                </div>
                <div v-if="(message.type==='TIMCustomElem') && (message.payload.extension !== '10009') && (message.payload.extension !== '10010')" class="text">
                  {{strToObj(message.payload.data).des}}
                </div>
                <div @click="jumpToConference(message)" v-else-if="(message.type==='TIMCustomElem') && ((message.payload.extension === '10009') || (message.payload.extension === '10010'))">
                  <div class="box">
                    <div class="l">
                      <span class="title">{{strToObj(message.payload.data).des}}</span>
                      <span>会议开始时间：</span>
                      <span class="time">{{strToObj(message.payload.data).time}}</span>
                    </div>
                    <div class="r">
                      <span>会议</span>
                    </div>
                  </div>
                </div>
                <!-- 点击查看大图 -->
                <div v-if="message.type==='TIMImageElem'" v-html="newImage(message)" @click="showLargeImg(message)"></div>
                <div v-if="message.type==='TIMFileElem'">
                  <div class="file" @click="openFile(message.payload.fileUrl, message.payload.fileName)">
                    <div class="l">
                      <span class="title">{{message.payload.fileName}}</span>
                      <span class="size">{{converFileSize(message.payload.fileSize)}}</span>
                    </div>
                    <div class="r">
                      <span v-if="getFileType(message.payload.fileName) == 'pdf'" class="pdf"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'ppt' || getFileType(message.payload.fileName) == 'pptx'" class="ppt"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'txt'" class="txt"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'doc' || getFileType(message.payload.fileName) == 'docx'" class="doc"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'xls' || getFileType(message.payload.fileName) == 'xlsx'" class="excel"></span>
                      <span v-else class="unknow"></span>
                    </div>
                  </div>
                </div>
                <div v-if="message.type==='TIMVideoFileElem'" v-html="newVideo(message)"></div>
                <div @click="yuyinPlay(message.payload.url,i)" v-if="message.type==='TIMSoundElem'" style="display: flex;justify-content:center;align-items:center;cursor:pointer;">
                  <!-- class="unread-sign" 未读气泡 -->
                  <img v-if="message.from !== currentFriendId" style="width:30px;height:20px;margin-right:8px;" :src="message.soundStatus" alt="">
                  <img v-else style="width:30px;height:20px;margin-right:8px;" :src="message.soundStatus" />
                  <span class="yuyinxiaoxi"></span>
                  <span>{{message.payload.second}}''</span>
                  <!-- 语音消息听取 -->
                  <audio :src="soundMessage" id="soundMsg"
                    @play="onPlay"
                    @pause="onPause"
                    @ended="onEnd"
                    ></audio>
                </div>
                <!-- call_type:2 视频通话   call_type:1 语音通话 -->
                <div v-if="message.type==='TIMCustomElem' && message.payload.data && strToObj(strToObj(message.payload.data).data).call_type" class="text">
                  <!-- 视频通话 -->
                  <div v-if="strToObj(strToObj(message.payload.data).data).call_type == 2">
                    <div v-if="strToObj(message.payload.data).actionType == 1 && !strToObj(strToObj(message.payload.data).data).call_end">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      发起视频通话
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 1 && strToObj(strToObj(message.payload.data).data).call_end">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      通话时长：{{formatSeconds(strToObj(strToObj(message.payload.data).data).call_end)}}
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 2">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      已取消
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 3">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      已接听
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 4">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      {{strToObj(strToObj(message.payload.data).data).line_busy == 'line_busy' ? '对方忙线' : '已拒绝'}}
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 5">
                      <span :class="{'othersShipinIcon': message.from == currentFriendId, 'myShipinIcon': message.from != currentFriendId}"></span>
                      无应答
                    </div>
                  </div>
                  <!-- 语音通话 -->
                  <div v-if="strToObj(strToObj(message.payload.data).data).call_type == 1">
                    <div v-if="strToObj(message.payload.data).actionType == 1 && !strToObj(strToObj(message.payload.data).data).call_end">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      发起语音通话
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 1 && strToObj(strToObj(message.payload.data).data).call_end">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      通话时长：{{formatSeconds(strToObj(strToObj(message.payload.data).data).call_end)}}
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 2">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      已取消
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 3">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      已接听
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 4">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      <!-- line_busy: 'line_busy' 显示忙线 -->
                      {{strToObj(strToObj(message.payload.data).data).line_busy == 'line_busy' ? '对方忙线' : '已拒绝'}}
                    </div>
                    <div v-if="strToObj(message.payload.data).actionType == 5">
                      <span :class="{'othersYuyinIcon': message.from == currentFriendId, 'myYuyinIcon': message.from != currentFriendId}"></span>
                      无应答
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- extension为JSON字符串 自定义内容 10001 消息 -->
            <div class="message-container" v-else-if="message.payload.data?.length === 0 && strToObj(message.payload.extension).type === 10001" :class="{'margin-time': showTime(i)}">
              <div class="message-text" v-if="message.type && strToObj(message.payload.extension).type !== 10000" >
                <div v-if="message.type==='TIMCustomElem'" class="text">
                  {{strToObj(message.payload.extension).des}}
                </div>
              </div>
            </div>
            <div class="message-container" v-else-if="(message.type==='TIMCustomElem') && ((message.payload.extension == 20020) || strToObj(message.payload?.data)?.type == 20020)" :class="{'margin-time': showTime(i)}">
              <div class="box-live" @click="toLive(message)">
                  <div class="l">
                    <span class="title">路演邀请</span>
                    <span class="time-title" v-if="strToObj(message.payload?.data)?.time">路演开始时间</span>
                    <span style="height:20px;" v-else></span>
                    <span class="time">{{strToObj(message.payload?.data)?.time || '路演分享'}}</span>
                  </div>
                  <div class="r">
                    <span>路演</span>
                  </div>
                </div>
            </div>
            <!-- 10030 10031 10032 分享消息 -->
            <div class="message-container" v-else-if="message.type == 'TIMCustomElem' && (message.payload.extension == 10030 || message.payload.extension == 10031 || message.payload.extension == 10032)" :class="{'margin-time': showTime(i)}">
              <div class="message-text share-message">
                <div class="share-box" @click="gotoShare(message.payload)">
                  <div class="l" v-if="message.payload.extension == 10030 || message.payload.extension == 10032">
                    <span class="title">{{strToObj(message.payload.data).title}}</span>
                    <span class="name">{{strToObj(message.payload.data).subTitle}}</span>
                  </div>
                  <div class="expert" v-else>
                    <div class="top-info">
                      <img :src="staticPath + strToObj(message.payload.data).headImage" alt="" v-if="strToObj(message.payload.data).dbDataStatus == 1 && !strToObj(message.payload.data).headImage?.startsWith('https')" :onerror="imgError">
                      <img :src="strToObj(message.payload.data).headImage" alt="" v-else-if="strToObj(message.payload.data).dbDataStatus == 1 && strToObj(message.payload.data).headImage?.startsWith('https')" :onerror="imgError">
                      <img :src="strToObj(message.payload.data).headImage" alt="" v-else :onerror="imgError">
                      <div class="name-info">
                        <div class="top">
                          <span class="name">{{strToObj(message.payload.data).name}}</span>
                          <span class="position" v-if="strToObj(message.payload.data).position && strToObj(message.payload.data).position !== 'null'">{{strToObj(message.payload.data).position}}</span>
                        </div>
                        <div class="belong">
                          <span class="name">{{'工作单位:' + (strToObj(message.payload.data).belong ? strToObj(message.payload.data).belong : '暂无')}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="direction" v-if="strToObj(message.payload.data).shortDirection">
                      {{strToObj(message.payload.data).shortDirection}}
                    </div>
                    <div v-else style="height: 5px;"></div>
                  </div>
                  <div class="r">
                    <img src="../../assets/images/im/share-publish.png" alt="" v-if="message.payload.extension == 10030 && !strToObj(message.payload.data).thumb">
                    <img :src="staticPath + strToObj(message.payload.data).thumb" :onerror="thumbError" alt="" v-else-if="message.payload.extension == 10030 && strToObj(message.payload.data).thumb">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 系统消息 -->
        <div v-else-if="currentFriendId === 'system'" v-loading="systemLoading">
          <div class="others-message message-line" v-for="(item,index) in systemMessages" :key="item.id">
            <div class="message-time-line" v-if="showTime(index)">
              {{item.sendTime}}
            </div>
            <!-- 系统消息的头像 -->
            <div class="message-avatar" :class="{'margin-time': showTime(index)}">
              <img :src="require('../../assets/images/square-avatar.png')" />
            </div>
            <div class="message-container" :class="{'margin-time': showTime(index)}">
              <div class="message-text" @click="jumpTo(item)">
                <div class="system-title">{{item.title}}</div>
                <div v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看图片消息大图 -->
        <el-dialog v-model="imageMessage" :append-to-body="false">
          <div v-html="newImage(imageMsgURL,1)" style="display:flex;align-items: center;justify-content: center;"></div>
        </el-dialog>
      </div>
      <!-- 输入框 -->
      <div class="input-block" v-if="currentFriendId && currentFriendId !== 'system'">
        <div class="function-bar">
          <div style="display:flex">
            <div v-loading="uploading" class="function-item" @click="startSelectImage">
               <span class="tupian"></span>
               <input type="file"
                      id="imagePicker" ref="imagePicker"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      v-show="false"
                      @change="sendPicture" />
              <span class="type-text">发送图片</span>
            </div>
            <div v-loading="fileuploading" class="function-item" @click="startSelectFile">
               <span class="file"></span>
               <input type="file"
                      id="filePicker" ref="filePicker"
                      v-show="false"
                      @change="sendFile" />
               <span class="type-text">发送文件</span>
            </div>
            <el-popover
              placement="top"
              :width="420"
              trigger="hover"
              content="表情">
              <template #reference>
                <div v-loading="emojiList.length === 0" class="function-item">
                  <span class="emoji"></span>
                  <span class="type-text">表情</span>
                </div>
              </template>
              <div class="emoji-list">
                <img v-for="(item,index) in emojiList" :key="index" :src="item.img" @click="insertEmoji(item.text)" class="emoji" />
              </div>
            </el-popover>
          </div>
        </div>
        <!-- 输入文字 -->
        <div class="input-area" @mousedown="checkAuthority('focus')">
          <el-input
            id="elementInput"
            ref="currentInput"
            v-model="currentFriend.inputText"
            type="textarea"
            :disabled="btnDisabled"
            @keypress="textareaKeyPress"
            @input="inputChange"
            @blur="inputLineBlur"
            :rows="3"
            resize="none"
            class="resizeNone"
          ></el-input>
        </div>
        <div class="send-line">
          <el-button :disabled="btnDisabled" size="mini" style="height: 30px" @click="sendText();checkAuthority()">发送</el-button>
        </div>
      </div>
    </div>
    <!-- 拉的环信的聊天内容 -->
    <div class="im-right-bar" v-if="!isSdkdata" @click="closeGroupTool">
      <!-- 名字 -->
      <div class="title-bar"  id="titleBar">
        <div class="current-title">
          <div class="current-friend-name">{{ currentFriend ? (currentFriend.name || currentFriend.userProfile.nick) : "" }}</div>
          <el-tag type="info" v-if="currentFriendId && !currentFriendStatus">离线</el-tag>
          <el-tag type="success" v-if="currentFriendId && currentFriendStatus">在线</el-tag>
        </div>
        <el-icon @click="hideWindow()" class="el-icon-circle-close close-button" style="color: #666666"></el-icon>
      </div>
      <!-- 内容 -->
      <div ref="messageBlock" class="message-block" v-if="currentFriendId">
        <!-- 上拉加载 -->
        <div @click="seeMore" class="shangla" v-if="currentFriendId !== 'system' && !currentFriend?.isCompleted">
          <img src="@/assets/images/shangla.png" alt="">
          <span>查看更多</span>
        </div>
        <!-- 系统消息 -->
        <div @click="seeMoreSystem" class="shangla" v-if="currentFriendId === 'system' && total !== systemMessages.length">
          <img src="@/assets/images/shangla.png" alt="">
          <span>查看更多</span>
        </div>
        <!-- 系统消息 -->
        <div v-if="currentFriendId === 'system'" v-loading="systemLoading">
          <div class="others-message message-line" v-for="(item) in systemMessages" :key="item.id">
            <!-- 系统消息的头像 -->
            <div class="message-avatar">
              <img :src="require('../../assets/images/square-avatar.png')" />
            </div>
            <div class="message-container">
              <div class="message-text">
                <div class="system-title">{{item.title}}</div>
                <div v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentFriendId !== 'system' && currentFriend?.messages && currentFriend?.messages.length > 0">
          <div class="message-line" v-for="(message, i) in currentFriend.messages" :key="i"
               :class="{'others-message': message.isSelf == false, 'my-message': message.isSelf == true}">
            <!-- 自定义内容 -->
            <div class="custom-wrap" v-if="message.customElem && message.customElem.extension === 10000">
              <div class="custom">{{strToObj(message.customElem.customMsg).des}}</div>
            </div>
            <div class="message-avatar" v-if="!message.customElem">
              <img v-if="message.isSelf == false" :src="userAvatar" />
              <img v-else :src="staticPath + avatar" />
            </div>
            <div class="message-container" v-if="!message.customElem">
              <div class="message-text">
                <div v-if="message.elemType==1" class="text">{{message.textElem.msg}}</div>
                <div v-if="message.elemType==3">
                  <div v-if="message.imageElem && message.imageElem.path">
                    <img style="width: 300px;height:200px" :src="message.imageElem.path" />
                  </div>
                  <div v-else>
                    <img style="width: 300px;height:200px" :src="strToObj(message.imageElem.imageList[0].url)[0].URL" />
                  </div>
                </div>
              </div>
            </div>
            <!-- extension为JSON字符串 自定义内容 10001 消息 -->
            <!-- <div class="message-container">
              <div class="message-text" v-if="message.type && strToObj(message.payload.extension).type !== 10000" >
                <div v-if="message.type==='TIMCustomElem'" class="text">
                  {{strToObj(message.payload.extension).des}}
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- 查看图片消息大图 -->
        <!-- <el-dialog v-model="imageMessage" :append-to-body="true">
          <div v-html="newImage(imageMsgURL,1)" style="display:flex;align-items: center;justify-content: center;"></div>
        </el-dialog> -->
      </div>
      <!-- 输入框 -->
      <div class="input-block" v-if="currentFriendId && currentFriendId !== 'system'">
        <div class="function-bar">
          <div style="display:flex">
            <div v-loading="uploading" class="function-item" @click="startSelectImage">
               <span class="tupian"></span>
               <input type="file"
                      id="imagePicker" ref="imagePicker"
                      accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                      v-show="false"
                      @change="sendPicture" />
            </div>
          </div>
        </div>
        <!-- 输入文字 -->
        <div class="input-area" @mousedown="checkAuthority('noFocus')">
          <el-input
            ref="noSDKInput"
            v-model="currentFriend.inputText"
            type="textarea"
            @keypress="textareaKeyPress"
            :rows="5"
            class="resizeNone"
            style="border: none; height: 100%; flex-grow: 1;"
          ></el-input>
        </div>
        <div class="send-line">
          <el-button :disabled="btnDisabled" size="mini" style="height: 30px" @click="sendText();">发送</el-button>
        </div>
      </div>
    </div>
    <div class="im-group-tools" v-if="showGroupTool">
      <GroupTool :gId="currentFriendId" @closeGroupTool="closeGroupTool" :chooseMember="chooseMember" @insertAtMemberName="insertAtMemberName"></GroupTool>
    </div>
  </div>
</template>

<script>
import { getAllSystemMessage , unreadSystemNum } from '@/api/im'
import TIM, { tim, timLogin,updateProfile, checkAvatar } from '@/config/WebTIMConfig'
import { mapGetters } from 'vuex'
import { staticPath,serviceId,TimNoShow,talentProjectSystemPage } from "@/utils/consts"
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import { ElMessage } from "element-plus"
import { getUserSig, getlistLatelyContact, getListLatelyMsgRecord, statusOne } from '@/api/tencent'
import { checkCommunicate,checkDynamicCommunicate } from "@/api/demand"
import { checkMemberChat } from "@/api/im"
import { getLocalTime, dateFormatToHour, mendZero , dateFormatToComplete , formatFullTime } from "@/utils/time.js"
import { canUpload } from "@/api/upload";
import { memberDetails,judgeBusinessVip,memberMessage } from "@/api/user";
import to from "await-to-js";
import { routerWindow } from "@/utils/tool";
import GroupTool from '@/components/im/components/GroupTool'
import { getTPSId } from "@/utils/talent-project-system/login";
import { getG60Id } from "@/utils/G60/login";
import { getTanId,getTanLocalInfo } from "@/utils/Tan/login";
import { getAIId } from "@/utils/AI/login";
import { getPLId } from "@/utils/post-line/login";
import BusinessWindow from '@/components/im/components/BusinessWindow';
import { tanVipJudge } from "@/utils/auth";


export default {
  name: "InstantMessage",
  components:{
    BusinessWindow,
    GroupTool,
  },
  computed: {
    TanInfo(){
      return getTanLocalInfo()?.memberInfo || {}
    },
    isGroup(){
      return this.currentFriend?.type === "GROUP"
    },
    isPL(){
      return PostLineSite
    },
    isG60(){
      return G60site
    },
    isTan(){
      return TanSite
    },
    isAI(){
      return AISite
    },
    isTps(){
      return talentProjectSystemPage.includes(this.$route.path)
    },
    notShowWindow(){
      return TimNoShow.includes(this.$route.path)
    },
    ...mapGetters(['id', 'avatar', 'name', 'imEasemobUsername','closeServiceWindow', 'imPassword', 'imWindowVisible', 'messageTrigger', 'messageTriggerServerid', 'messageTriggerTitle', 'messageUnlimited', 'messageQuickchat', 'toMemberId','imDialogVisible','closed','shareMessage','shareInfos','visibility','timChange',
      'leaveMessageVisible',  // 留言窗口
      'demandInfo', // 需求消息
      'otherInfo',  // 其他消息
    ]),
  },
  data() {
    return {
      newMessageTab:{
        title: '',
        time: 0,
        interval: null
      },
      inputSelectStatus: {
        start: null,
        end: null,
      },
      emojiList: [],
      showBusinessWindow: false,
      showGroupTool: false, // 显示群管理内容
      chooseMember: false, // 艾特消息选择人
      atInfos: {  // 艾特信息
        num: 0,
        val: []
      },
      serviceId, // 客服ID
      systemMessageNum: 0,  // 系统消息数
      total: 0, // 系统消息总数
      offset: 1, // 消息分页
      systemMessages: [], // 系统消息
      shareName: '',  // 好友名字
      shareId: '',  // 分享给的好友ID
      shareHide: false, // 是否在分享时关闭聊天界面
      shareCancel: false, // 取消分享
      shareWindow: false, // 展示分享弹窗
      myId: '',
      loadHeadImage: [],  // 存储从接口获取的头像及用户ID
      soundIndex: -1,  // 当前播放的语音
      soundMessage: '',// 语音消息.mp3链接
      imageMessage: false,  // 图片信息放大
      imageMsgURL: '',      // 图片大图URL
      defferScroll: function (event) { // 阻止鼠标滚动
        event.preventDefault()
      },
      currentFriendStatus: false,
      friends: [],
      btnDisabled: false,
      currentFriendId: null,
      staticPath,
      uploading: false,
      fileuploading: false,
      updating: false,
      currentFriend: null,
      tempfriendObj: null,
      isSdkdata: true,
      pageNum: 1, //接口拉环信消息的当前页
      isVip: false,
    }
  },
  directives:{
    drag(el){
      el.onmousedown = function(e){
        if(!['selfInfo','titleBar'].includes(e.target.id)) return
        let disx = e.clientX - el.offsetLeft;
        let disy = e.clientY - el.offsetTop;
        document.onmousemove = function (e){
            el.style.left = e.clientX - disx < 0 ? 0 + 'px' : e.clientX - disx +'px';
            el.style.top = e.clientY - disy < 0 ? 0 + 'px' : e.clientY - disy + 'px';
        }
        document.onmouseup = function(){
            document.onmousemove = document.onmouseup = null;
        }
      }
    },
  },
  watch: {
    showGroupTool(val){
      // 关闭时进行重置
      if(!val) this.chooseMember = false
    },
    timChange(){
      // tim状态修改
      this.initTIMConnection()  // 重新初始化
    },
    leaveMessageVisible(val){
      // 打开留言窗口则关闭聊天窗口
      if(!val) return
      this.hideWindow()
    },
    visibility(val){
      // 返回页面的事件
      let _this = this
      if (val) {
        // 切换到该页面时执行
        if(this.newMessageTab.interval){
          // * 清除计时器
          clearInterval(this.newMessageTab.interval)
          this.newMessageTab.interval = null
          this.newMessageTab.time = 0
          document.title = this.newMessageTab.title
          this.newMessageTab.title = ''
        }
        if(!localStorage.getItem("userInfo")) return  // 空判断
        else if(this.isG60 || this.isTan || this.isAI) return
        let promise = tim.getUserStatus({userIDList: [`${this.myId}`]});
        promise.then(function(imResponse) {
          const { successUserList } = imResponse.data;
          successUserList.forEach((item) => {
            const { statusType } = item;
            // userID - 用户 ID
            // statusType - 用户状态，枚举值及说明如下：
            // TIM.TYPES.USER_STATUS_UNKNOWN - 未知
            // TIM.TYPES.USER_STATUS_ONLINE - 在线
            // TIM.TYPES.USER_STATUS_OFFLINE - 离线
            // TIM.TYPES.USER_STATUS_UNLOGINED - 未登录
            if(statusType === TIM.TYPES.USER_STATUS_UNLOGINED || statusType === TIM.TYPES.USER_STATUS_OFFLINE){
              // 未登录状态
              _this.initTIMConnection()  // 重新初始化
            }
          });
        }).catch(function(imError) {
          console.warn('获取用户信息失败:', imError); // 获取用户状态失败的相关信息
        })
      }
    },
    closeServiceWindow(){
      // 退出登录关闭聊天
      this.hideWindow()
    },
    shareWindow(val){
      if(!val){
        this.shareId = ''
        this.shareCancel = false  // 关闭时取消
      }
    },
    async $route() {  // 路由变化时，获取系统消息
      let userInfo = localStorage.getItem("userInfos");
      if(!userInfo) return  // 空判断
      userInfo = JSON.parse(localStorage.getItem("userInfos")).memberInfo
      if(this.isTps){
        if(!getTPSId()) return
        userInfo.id = getTPSId()
      }
      else if(this.isG60){
        if(!getG60Id()) return
        userInfo.id = getG60Id()
      }
      else if(this.isTan){
        if(!getTanId()) return
        userInfo.id = getTanId()
      }
      else if(this.isAI){
        if(!getAIId()) return
        userInfo.id = getAIId()
      }
      else if(this.isPL){
        if(!getPLId()) return
        userInfo.id = getPLId()
      }
      let res = await unreadSystemNum(userInfo.id)
      this.systemMessageNum = (res && res.data) ? res.data : 0
      this.$store.dispatch("message/setSystemMessageNum",this.systemMessageNum) // 路由变化时再获取剩余未读数
    },
    async messageTrigger(val) { //messageTrigger - 要添加的好友会员id
      if (!val) return;
      var flag = true
      // 查找该聊天对象的信息
      this.friends.forEach((item) => {
        if (item.conversationID == 'C2C'+this.messageTrigger) {
          flag = false
          this.switchToFriend(val)
          if(this.demandInfo.id.length > 0){  // 需要10000、10001消息
            this.sendDemandMessage()  // 发送自定义消息
          }
        }
      })
      flag && await memberMessage(val).then(async (res) => {
        let { headImage, name } = res.data
        this.tempfriendObj = {
          conversationID: 'C2C'+val,
          memberId: val + '',
          userProfile: {
            avatar: staticPath + headImage
          },
          name
        }
        this.friends.unshift(this.tempfriendObj)
        await this.switchToFriend(val) // 改为异步操作,防止 currentFriend 为空 currentFriendId 为空
        if(this.demandInfo.id.length > 0){  // 需要10000、10001消息
          this.sendDemandMessage()  // 发送自定义消息
        }
      })
      // 如果近期已经发过标题/一键沟通，不要重复发
      var custom10000Flag = true
      var custom10001Flag = true
      setTimeout(async () => {
        this.currentFriend.messages && this.currentFriend.messages.forEach((item) => {
          let tempDes = this.strToObj(item.payload.data).des
          if (item.payload.extension === '10000' && (tempDes == this.messageTriggerTitle)) {
            custom10000Flag = false
          }
          if (item.payload.extension === '10001' && this.strToObj(item.payload.data).des.slice(19,-12)=== this.messageTriggerTitle){  // des为整句，则slice出需求标题
            custom10001Flag = false
          }
          if (item.payload.extension === '10001' && (this.strToObj(item.payload.data).des == this.messageTriggerTitle)) {
            custom10001Flag = false
          }
        })
        if (custom10000Flag) {
          // 从联系需求方进来 - 发送自定义消息 - 对话框中出现需求标题
          if(this.messageTriggerServerid && this.messageTriggerTitle) {
            let cuntomElem = {
              "des": this.messageTriggerTitle,
              "id": this.messageTriggerServerid, // 需求id
              "source": 'pc',
              "type": 10000
            }
            let message = await tim.createCustomMessage({
              to: ''+this.messageTrigger,
              conversationType: TIM.TYPES.CONV_C2C,
              payload: {
                data: JSON.stringify(cuntomElem),
                description: this.messageTriggerTitle,
                extension: '10000'  // 用于标识‘需求服务’的类型
              }
            })
            await tim.sendMessage(message).then(res => {  // 等待发送10000消息后再执行之后语句
              this.currentFriend.messages.push(res.data.message)
              this.scrollMessageBlockToBottom()
            })
          }
        }
        if (custom10001Flag) {
          // 一键沟通 (this.messageTriggerServerid && this.messageTriggerTitle && this.messageQuickchat)
          if (this.messageTriggerServerid && this.messageTriggerTitle) {
            // 判断是否已经发送过需求
            await checkCommunicate(this.messageTriggerServerid, this.currentFriendId).then(async res => {
              if (res.data.firstSend == true) {
                await this.findCommunicateMsg(res.data.text)
              }
            })
          }
        }
      }, 500)
    },
    async imDialogVisible(val) {    // 非会员记录读取
      if (val === true) { // 不是会员，没必要触发会员
        // await this.updateFriendsList()
      }
    },
    imageMessage(val){  // 清空IMG
      if(!val) {
        this.imageMsgURL = ''
        // document.body.removeEventListener("wheel", this.defferScroll, {passive: false}) // 阻止
      }
      else{
        // document.body.addEventListener("wheel", this.defferScroll, {passive: false}) // 恢复
      }
    },
    imWindowVisible(val){ // 聊天窗口是否打开
      if(val){  // 打开窗口
        // if(this.otherInfo.id.length > 0){ // 动态或新闻联系
        //   this.sendOtherMessage() // 发送文字消息
        // }
        this.setHeight()
        this.getSystemMessage(this.offset)
        if(this.currentFriendId?.length > 0){ // 返回底部
          this.$nextTick(()=>{
            this.scrollMessageBlockToBottom()
          })
        }
      }
      else{
        this.cancelShare()  // 关闭窗口时取消分享
      }
    },
  },
  mounted() {
    setTimeout(()=>{  //加定时是因为组件在App.vue中一上来就拿数据，这时缓存中的数据不是最新的
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const userInfos = JSON.parse(localStorage.getItem('userInfos'))?.memberInfo
      this.isVip = userInfo?.isVip
      this.myId = userInfos?.id || userInfo?.id
      if(this.isTps){
        if(!getTPSId()) return
        this.myId = getTPSId()
      }
      else if(this.isG60){
        if(!getG60Id()) return
        this.myId = getG60Id()
        return  // ? 暂时隐藏聊天功能
      }
      else if(this.isTan){
        if(!getTanId()) return
        this.myId = getTanId()
      }
      else if(this.isAI){
        if(!getAIId()) return
        userInfo.id = getAIId()
      }
      else if(this.isPL){
        if(!getPLId()) return
        this.myId = getPLId()
      }
      this.initTIMConnection()
    }, 1500)

    window.onresize = (()=>{  // 重置高度
      if(this.imWindowVisible){
        this.setHeight()
      }
    })
    this.getEmojis()
  },
  unmounted () {
    tim.logout().then((imRes) => {
      // 退出登录信息
      console.log('tim logout info:',imRes)
    }).catch((imErr) => {
      console.error('tim logout error:',imErr)
    })
  },
  methods: {
    getTanLocalInfo,
    changeLastMessage(text,obj){
      if(obj?.type !== "GROUP") return text
      else if(obj?.groupAtInfoList.length === 0) return text
      let atMe = false
      let atAll = false
      for (let index = 0; index < obj?.groupAtInfoList.length; index++) {
        const element = obj?.groupAtInfoList[index].atTypeArray[0];
        switch (element) {
          case TIM.TYPES.CONV_AT_ME:
            atMe = true
            break;
          case TIM.TYPES.CONV_AT_ALL:
            atAll = true
            break;
          case TIM.TYPES.CONV_AT_ALL_AT_ME:
            atAll = true
            atMe = true
            break;
          default:
            break;
        }
      }
      if(atAll) text = '<span style="color: #E51616;">[@所有人]</span>' + text
      if(atMe) text = '<span style="color: #E51616;">[有人@我]</span>' + text
      return text
    },
    insertAtMemberName(obj){
      if(!obj) return
      const name = obj.name + ''
      this.atInfos.val.push(obj)
      // 获取对应input的DOM
      const inputDOM = document.getElementById('elementInput')
      if(!inputDOM) return
      const startCursor = this.inputSelectStatus.start; // 开始光标位置
      const endCursor = this.inputSelectStatus.end; // 结束光标位置
      if (startCursor === null && endCursor === null){
        if(!this.currentFriend.inputText){
          this.currentFriend.inputText = ''
        }
        this.currentFriend.inputText += name
        return  /* 当未获取焦点时 在此处处理 默认直接将内容拼接最后 */;
      }
      const txt = inputDOM.value;
      // 最终的处理结果
      this.currentFriend.inputText = txt.substring(0, startCursor) + name + txt.substring(endCursor);
      this.inputSelectStatus = {
        start: startCursor + name.length,
        end: startCursor + name.length,
      }
      this.showGroupTool = false
    },
    inputChange(value){
      // 判断是否输入了@符号
      const l = value.match(/@/g)?.length
      if(!l) {
        this.atInfos.num = 0
        this.atInfos.val = []
        return
      }
      else if(l <= this.atInfos.num){
        this.atInfos.num = l
        return
      }
      this.atInfos.num = l
      this.showGroupTool = true
      this.chooseMember = true
    },
    inputLineBlur(e){
      // 对选中状态进行记录
      const inputDOM = e.srcElement
      if(!inputDOM) return
      const startCursor = inputDOM.selectionStart; // 开始光标位置
      const endCursor = inputDOM.selectionEnd; // 结束光标位置
      console.log(startCursor,endCursor);
      if (startCursor === undefined || endCursor === undefined){
        this.inputSelectStatus = {
          start: null,
          end: null,
        }
      }
      else{
        this.inputSelectStatus = {
          start: startCursor,
          end: endCursor,
        }
      }
    },
    insertEmoji(text){
      // 插入表情
      // 获取对应input的DOM
      const inputDOM = document.getElementById('elementInput')
      if(!inputDOM) return
      const startCursor = this.inputSelectStatus.start; // 开始光标位置
      const endCursor = this.inputSelectStatus.end; // 结束光标位置
      if (startCursor === null && endCursor === null){
        if(!this.currentFriend.inputText){
          this.currentFriend.inputText = ''
        }
        this.currentFriend.inputText += text
        return  /* 当未获取焦点时 在此处处理 默认直接将内容拼接最后 */;
      }
      const txt = inputDOM.value;
      // 最终的处理结果
      this.currentFriend.inputText = txt.substring(0, startCursor) + text + txt.substring(endCursor);
      this.inputSelectStatus = {
        start: startCursor + text.length,
        end: startCursor + text.length,
      }
    },
    getEmojis(){
      // 获取本地的emoji
      const files = require.context("../../assets/images/emojis", false, /\.(png)$/);
      files.keys().forEach(key => {
        const img = key.slice(2)
        const end = key.indexOf(']') + 1
        this.emojiList.push({
          img: require(`../../assets/images/emojis/${img}`),
          text: `${key.slice(2,end)}`
        })
      })
    },
    formatFullTime,
    changeEmoji(word){
      // 替换显示表情的部分
      const reg2 = /(?<=\[)(.+?)(?=\])/g;   // [] 中括号
      const arr = word.match(reg2)
      // 输出是一个数组
      if(!arr) return word
      for (let index = 0; index < arr.length; index++) {
        const f = arr[index];
        try {
          const img = require(`../../assets/images/emojis/[${f}]@2x.png`)
          word = word.replaceAll(`[${f}]`,`<img style="width: 24px;height: 24px;margin: 0 2px;" src="${img}" />`)
        } catch (err) {
          continue
        }
      }
      return word
    },
    changeWindow(val){
      this.showBusinessWindow = val
    },
    toSpace(info){
      if(info.from + '' === this.myId + '' || !info.from) return // 自己的消息
      // 如果不是群组直接判断用户空间
      if(info.conversationType !== "GROUP"){
        memberDetails(info.from).then(res=>{
          if(!res.data?.organizationId){
            ElMessage.warning('该用户没有创建空间!')
            return
          }
          if(res.data.organizationType + '' === '1'){
            routerWindow(this.$router,'/lab',{id: res.data.organizationId,identity: '1',})

          }
          else routerWindow(this.$router,'/my-lab',{id: info.from,})
        }).catch(err=>{
          console.error("获取用户信息错误：",err);
        })
        return
      }
      // 如果是群组先判断商学院VIP
      judgeBusinessVip({
        memberId: this.myId
      }).then(res=>{
        if(!res.data){
          this.showBusinessWindow = true
          return
        }
        memberDetails(info.from).then(res=>{
          if(!res.data?.organizationId){
            ElMessage.warning('该用户没有创建空间!')
            return
          }
          if(res.data.organizationType + '' === '1'){
            routerWindow(this.$router,'/lab',{id: res.data.organizationId,identity: '1',})

          }
          else routerWindow(this.$router,'/my-lab',{id: info.from,})
        }).catch(err=>{
          console.error("获取用户信息错误：",err);
        })
      })
    },
    closeGroupTool(){
      if(this.showGroupTool) this.showGroupTool = false
    },
    async sendDemandMessage(){
      // 发送需求信息
      let isFirst = {}
      if (!this.demandInfo.isDynamic) {
        // 是动态
        isFirst = await checkCommunicate(this.demandInfo.id, this.currentFriendId)
      }
      else{
        isFirst = await checkDynamicCommunicate(this.demandInfo.id, this.currentFriendId)
      }
      if(!isFirst.data.firstSend) return
      this.btnDisabled = true
      this.switchToFriend(this.currentFriendId).then(async ()=>{
        // 切换到客服
        let customElem = {
          "des": this.demandInfo.title,
          "id": this.demandInfo.id, // 需求id
          "source": 'pc',
          "type": 10000
        }
        let message = await tim.createCustomMessage({
          to: ''+ this.currentFriendId,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            data: JSON.stringify(customElem),
            description: this.demandInfo.title,
            extension: '10000'  // 用于标识‘需求服务’的类型
          }
        })
        this.btnDisabled = true
        tim.sendMessage(message).then(res => {  // 等待发送10000消息后再执行之后语句
          this.currentFriend.messages.push(res.data.message)
          let cuntomElem = {
            "des": isFirst.data.text,
            "dynamic": this.demandInfo.isDynamic ? '1' : '0',
            "id": this.demandInfo.id,
            "source": 'pc',
            "type": 10001
          }
          let message1 = tim.createCustomMessage({
            to: '' + this.currentFriendId,
            conversationType: TIM.TYPES.CONV_C2C,
            payload: {
              data: JSON.stringify(cuntomElem),
              extension: '10001' // 用于标识‘一键沟通’的类型
            }
          })
          tim.sendMessage(message1).then(res => {
            this.btnDisabled = false
            if(!this.currentFriend.messages || !this.currentFriend){  // 防止未定义
              this.switchToFriend(this.currentFriendId)
            }
            else{
              this.currentFriend.messages.push(res.data.message)
            }
            this.currentFriend.inputText = ''
            this.scrollMessageBlockToBottom()
            this.$store.commit('message/SET_DEMAND_INFO',{id: '', title: ''})
            updateProfile()
          }).catch((err)=>{
            console.error('send demand custom message error:',err);
            this.btnDisabled = false
          })
        }).catch((err)=>{
          console.error('send demand custom message error:',err);
          this.btnDisabled = false
        })
      })
    },
    sendOtherMessage(){
      // 动态或新闻消息
      this.btnDisabled = true
      this.switchToFriend(this.serviceId).then(()=>{
        // 切换到客服
        let message = tim.createTextMessage({
          to: this.serviceId + '', //消息接收方的userID,字符串类型
          conversationType: TIM.TYPES.CONV_C2C, //类型：端到端会话
          payload: {
            text: `我对“${this.otherInfo.title}”感兴趣，请问方便沟通吗？`
          }
        })
        tim.sendMessage(message).then((res) => {
          this.btnDisabled = false
          if(!this.currentFriend.messages || !this.currentFriend){  // 防止未定义
            this.switchToFriend(this.currentFriendId)
          }
          else{
            this.currentFriend.messages.push(res.data.message)
          }
          this.currentFriend.inputText = ''
          this.scrollMessageBlockToBottom()
        }).catch((error)=>{
          console.error(error);
        })
        updateProfile()
        this.$store.commit('message/SET_OTHER_INFO',{id: '', title: ''})
      })
    },
    showTime(index){  // 判断是否间隔 5分钟
      if(this.currentFriendId === 'system'){  // 系统消息
        if(index === 0 || (new Date(this.systemMessages[index].sendTime).getTime() - new Date(this.systemMessages[index - 1].sendTime).getTime() > 300000)){
          return true
        }
        return false
      }
      if(!this.currentFriend.messages[index]) return false
      if(index === 0 || this.currentFriend.messages[index].time - this.currentFriend.messages[index - 1].time > 3000){
        return true
      }
      return false
    },
    backToShare(){
      this.shareCancel = false
      if(this.shareId.length === 0){
        this.shareWindow = false
      }
    },
    async seeMoreSystem(){
      this.offset++
      let userInfo = localStorage.getItem("userInfo");
      if(!userInfo) return  // 空判断
      userInfo = JSON.parse(userInfo)
      let res = ''
      this.systemLoading = true
      try {
        res = await getAllSystemMessage({memberId:userInfo.id,offset:this.offset})
        if(res && res.data){
          this.systemMessages = [...res.data.result.reverse(),...this.systemMessages]
        }
        this.total = (res && res.data) ? res.data.total : 0
        this.systemLoading = false
        res = await unreadSystemNum(userInfo.id)
        this.systemMessageNum = (res && res.data) ? res.data : 0
        this.$store.dispatch("message/setSystemMessageNum",this.systemMessageNum) // 获取系统消息后再获取剩余未读数
      } catch (error) {
        console.error('get more system message error:',error);
        this.systemLoading = false
      }
    },
    async getSystemMessage(offset){
      let userInfo = localStorage.getItem("userInfo");
      if(!userInfo) return  // 空判断
      userInfo = JSON.parse(userInfo)
      let res = ''
      this.systemLoading = true
      try {
        let i = 1
        this.systemMessages = []  // 获取前清空
        while(i <= offset){ // 根据页数持续获取
          res = await getAllSystemMessage({memberId:userInfo.id,offset:i})
          if(res && res.data){
            this.systemMessages = [...res.data.result.reverse(),...this.systemMessages]
          }
          else{
            i = offset
          }
          i++
        }
        this.total = (res && res.data) ? res.data.total : 0
        this.systemLoading = false
        this.scrollMessageBlockToBottom()
      } catch (error) {
        console.error('get system message error:',error);
        this.systemLoading = false
      }
    },
    thumbError(event){ // 封面加载错误
      let img = event.srcElement
      img.src = require('../../assets/images/im/share-publish.png')
      img.onerror = null
    },
    imgError(event){  // 加载错误
      let img = event.srcElement
      img.src = require('../../assets/images/square-avatar.png')
      img.onerror = null
    },
    async gotoShare(message){ // 前往分享页面
      let data = JSON.parse(message.data)
      if(['/my-lab','/my-lab-doctor'].includes(this.$route.path)){  // 返回首页
        this.$router.push('/')
      }
      if(data.type == 10030){
        setTimeout(()=>{
          routerWindow(this.$router,'/detail',{dynamicId:data.id})
        },500)
        return
      }
      else if(data.type == 10032){
        setTimeout(()=>{
          routerWindow(this.$router,'/demand-detail',{content: "1", publishId:data.id})
        },500)
        return
      }
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let res = await memberDetails(userInfo.id)
      if(!res.data.isVip){  // 实时获取信息
        this.$store.dispatch("message/hideIMWindow")
        this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
        return
      }
      if(data.dbDataStatus == 1){
        setTimeout(()=>{
          routerWindow(this.$router,'/my-lab',{id: data.id})
        },500)
      }
      else{
        setTimeout(()=>{
          routerWindow(this.$router,'/my-lab-doctor',{expertId: data.id})
        },500)
      }
    },
    changeShare(){  // 修改分享对象
      this.shareWindow = false
      this.shareCancel = false
      this.shareId = ''
    },
    cancelShare(){  // 取消分享
      this.shareWindow = false
      this.$store.dispatch("message/setShareInfos",{})
      this.$store.dispatch("message/setShareMessage",false)
      this.shareCancel = false
      this.shareId = ''
      if(this.shareHide){
        this.hideWindow()
        this.shareHide = false
      }
    },
    sendShareMessage(type,id){  // 发送分享消息
      let that = this
      let cuntomElem = {}
      if(type == 10030){
        cuntomElem = {
          "title": this.shareInfos.title,
          "subTitle" : this.shareInfos.subTitle,
          "id": this.shareInfos.id + '',
          "source": 'pc',
          "publisherId": this.shareInfos.publisherId + '',
          "thumb": this.shareInfos.thumb,
          "type": '10030'
        }
      }
      else if (type == 10031){
        cuntomElem = {
          "dbDataStatus": this.shareInfos.dbDataStatus,
          "headImage": this.shareInfos.headImage,
          "name": this.shareInfos.name,
          "belong": this.shareInfos.belong,
          "position": this.shareInfos.position,
          "shortDirection": this.shareInfos.shortDirection,
          "id": this.shareInfos.id + '',
          "source": 'pc',
          "type": '10031'
        }
      }
      else if (type == 10032){
        cuntomElem = {
          "id": this.shareInfos.id + '',
          "title": this.shareInfos.title + '',
          "subTitle": this.shareInfos.subTitle + '',
          "source": 'pc',
          "type": '10032'
        }
      }
      let message = tim.createCustomMessage({
        to: '' + id,
        conversationType: this.currentFriend.type === "C2C" ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
        payload: {
          data: JSON.stringify(cuntomElem),
          extension: type
        }
      })

      tim.sendMessage(message).then(async () => {
        ElMessage.success('分享成功！')
        // that.cancelShare()
        await that.switchToFriend(id)
        that.shareWindow = false
        that.scrollMessageBlockToBottom()
      })
    },
    setHeight(){  // 设定高度
      this.$nextTick(()=>{
        let container = document.getElementById('imContainer')
        const height = document.documentElement.offsetHeight * 0.8 >= 300 ? document.documentElement.offsetHeight * 0.8 : 300
        container.style.height = height  +'px'
        let friendList = document.getElementById('friendList')
        if(!friendList) return
        friendList.style.height = height - 70  +'px'
      })
    },
    openFile (url, name) {
      const ext = this.getFileType(name)
      if (ext == 'doc' || ext == 'docx' || ext=='ppt' || ext=='pptx' || ext=='xls' || ext=='xlsx') {
        // embed 只显示内容区
        window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + url)
      } else if (ext == 'txt'){
        // 解决txt预览乱码问题
        const req = new XMLHttpRequest();
        req.open("get", url, true);
        req.responseType = "blob";
        req.onload = function () {
          // 在预览txt时需要转换成utf-8
          // const data = new Blob([req.response], {type: 'application/json;charset=utf-8'});
          const reader = new FileReader()
          reader.onload = function () {
            let data = ''
            if(reader.result.indexOf('�') !== -1) data = new Blob([req.response], {type: 'charset=utf-8'});
            else data = new Blob([req.response], {type: 'application/json;charset=utf-8'});
            let blobUrl = window.URL.createObjectURL(data);
            window.open(blobUrl)
          }
          reader.readAsText(this.response);
        }
        req.send()
      } else if (ext == 'pdf') {
          window.open(url)
      }else {
        ElMessage.error('暂不支持预览此类文件')
      }
    },
    fileDragover (e) {
      e.preventDefault()
    },
    async fileDrop (e) {
      e.preventDefault()
      const file = e.dataTransfer.files[0]
      if (!file) return
      var index= file.name.lastIndexOf(".");//获取最后一个.的位置
      var ext = file.name.substr(index+1);//获取后缀
      try {
        let res = await canUpload(file.name)
        if (res.data) {
          let message = tim.createFileMessage({
            to: this.currentFriendId + '',
            conversationType: TIM.TYPES.CONV_C2C,
            payload: {
              file
            },
            onProgress: (event) => {
              console.log('file uploading:', event)
            }
          })
          message.payload.uuid = message.payload.uuid +'.'+ ext
          this.fileuploading = true
          tim.sendMessage(message).then((res) => {
            this.currentFriend.messages.push(res.data.message)
            this.scrollMessageBlockToBottom()
          this.fileuploading = false
          }).catch(err => {
            this.fileuploading = false
            ElMessage.error(err)
          })
        } else {
          ElMessage.error('暂不支持此类型文件上传')
        }
      } catch (err) {
        ElMessage.error(err)
      }
    },
    converFileSize (limit) {
      var size = "";
      if( limit < 0.1 * 1024 ){ //如果小于0.1KB转化成B
          size = limit.toFixed(2) + "B";
      }else if(limit < 0.1 * 1024 * 1024 ){//如果小于0.1MB转化成KB
          size = (limit / 1024).toFixed(2) + "KB";
      }else if(limit < 0.1 * 1024 * 1024 * 1024){ //如果小于0.1GB转化成MB
          size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      }else{ //其他转化成GB
          size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = size + "";
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if(dec == "00"){//当小数点后为00时 去掉小数部分
          return sizestr.substring(0,len) + sizestr.substr(len + 3,2);
      }
      return sizestr;
    },
    checkLiveLink(item){ // 判断路演链接
      if(item.payload.data){
        let payload = JSON.parse(item.payload.data)
        if(Number(payload.type) === 20020){ // uat环境
          item.payload.text = 'uwww.keqidao.com/live?roomId=' + item.payload.id+'memberId='+item.payload.memberId
          item.payload.liveTime = item.payload.time
          return true
        }
      }
      // let text = ''    // 不对链接进行判断
      // text = item.payload.text
      // let reg = /^[http(s)://]/g
      // let qidao = ['localhost:3000/live-room?roomId=','uwww.keqidao.com/live-room?roomId=','keqidao.com/live-room?roomId=']
      // let flag = reg.test(text)
      // let isLegal = false
      // if(flag){
      //   qidao.forEach(async (jtem)=>{
      //     if(text.indexOf(jtem) !== -1){
      //       let index = 0
      //       for(let i = text.length-1;i>=0;i--){
      //         if(isNaN(text[i]))break
      //         index++
      //       }
      //       if(index === 15){
      //         isLegal = true
      //         // item.payload.liveTime = '-----'
      //       }
      //     }
      //   })
      // }
      // return isLegal
    },
    toLive(message){  // 链接跳转路演界面
      let payload = message.payload.text
      if(message.payload.data){
        payload = JSON.parse(message.payload.data)
      }
      let roomId = payload.id
      this.$router.push({
        path: '/live',
        query: {
          'roomId': roomId,
          'memberId':payload.userId,
          'type':'1'
        }
      })
      this.$store.dispatch('message/hideIMWindow')  // 关闭聊天界面
    },
    isJson(str){  // 判断JSON
      if (typeof str == 'string') {
          try {
              let obj=JSON.parse(str);
              if(typeof obj == 'object' && obj ){
                  return true;
              }else{
                  return false;
              }
          } catch(e) {
              return false;
          }
      }
      return false
    },
    jumpTo(message){  // 系统消息跳转
      if(message.pathApp && message.pathApp.indexOf('liveRoom?roomId=') !== -1){  //  路演跳转
        let start = message.pathApp.indexOf('liveRoom?roomId=')
        let end = message.pathApp.indexOf('&',start)
        let roomId = message.pathApp.slice(start + 16,end)
        this.$router.push('/')
        let that = this
        setTimeout(()=>{
          that.$router.push({path:'/live',query:{
            "roomId" : roomId
          }})
          that.hideWindow()
        },100)
      }
      else if(message.pathApp && message.pathApp.indexOf('dyId') !== -1){ // 动态跳转
        let start = message.pathApp.indexOf('dyId')
        let end = message.pathApp.indexOf('&subscribeId',start)
        let dynamicId = message.pathApp.slice(start + 5,end)
        setTimeout(()=>{
          routerWindow(this.$router,'/detail',{dynamicId: dynamicId})
        },100)
      }
      else if(message.pathPc){  // 其他PC路径
        setTimeout(()=>{
          routerWindow(this.$router,message.pathPc,{})
        },100)
      }
      else if(message.pathApp === '/pages/service/addReq/addReq?type=1'){
        setTimeout(()=>{
          routerWindow(this.$router,'/postdemand',{})
        },100)
      }
      else if(message.pathApp === '/pages/my/company/details'){
        let userInfo = localStorage.getItem("userInfo");
        if(!userInfo) return
        userInfo = JSON.parse(userInfo);
        memberDetails(userInfo.id).then(res=>{
          if (
            !userInfo.organizationId ||
            (res.data.organizationName.startsWith(res.data.phone) && res.data.organizationName.length === (res.data.phone + '').length)
          ) {
            // 包括普通用户
            // this.$store.dispatch("popup/setPopup", {
            //   type: 11, // 创建身份弹窗
            //   parameter: {},
            // });
            tanVipJudge({
              pRes: res,
            })
            return;
          }
          this.$store.dispatch("popup/setPopup", {
            type: 13, // 修改数字科创
            parameter: {},
          });
        })
      }
      else if(message.pathApp === '/pages/my/emil/emil'){
        // 我的邮件列表
        let that = this
        setTimeout(()=>{
          that.$router.push({
            path: '/user-center',
            query:{
              keyPath: JSON.stringify(['9']),
              page: 1
            }
          })
          that.hideWindow()
        },100)
      }
      else if(message.pathApp === '/pages/my/emil/info/info?id=xxx'){
        // 邮件详情
        let that = this
        let id = message.pathApp.slice(message.pathApp.indexOf('=') + 1)
        setTimeout(()=>{
          that.$router.push({
            path: '/user-center',
            query:{
              keyPath: JSON.stringify(['9']),
              page: 1,
              detailId: id
            }
          })
          that.hideWindow()
        },100)
      }
    },
    jumpToConference (val) { //链接跳转到会议页面
      // let userInfo =  localStorage.getItem('userInfo')
      // userInfo = JSON.parse(userInfo)
      let { id,type } = JSON.parse(val.payload.data)
      let inTheGroup = false
      let that = this
      tim.getGroupMemberList({  // 判断是否在其他端登录
        groupID: id + '', // 字符串形式
        count: 100,
        offset: 0     // 从0开始拉取100个群成员
      }).then((res)=>{
        res.data.memberList.forEach(item=> {
          if(item.role === 'Owner') that.memberName = item.nick // 获取主持人名称
          item.memberCustomField.forEach(attr => {
            if (attr.key == 'line_status') {
              // app用户状态为JSON格式
              if(this.isJson(attr.value)){
                attr.value = JSON.parse(attr.value);
              }
            }
          })
        })
        if(inTheGroup){
          return
        }
        let userInfo = localStorage.getItem("userInfo");
        if(!userInfo) return  // 空判断
        userInfo = JSON.parse(userInfo)
        tim.getGroupMemberProfile({  // 查看自己的群成员资料 判断是否被踢出
          "groupID": id + '',
          userIDList: [userInfo.id + '']
        }).then(res => {
          console.log('tim get group member profile:', res)
          let member = res.data.memberList[0]
          if (member.nameCard == 'true') {
            ElMessage.error('您已被踢除，不可进入')
          }
          else{
            tim.joinGroup({ // 未被踢出则直接进入
              "groupID": id + ''
            }).then(() => {
              tim.setGroupMemberCustomField({
                groupID: id+'',
                memberCustomField: [{ key: 'line_status', value: JSON.stringify({bind_device: 'pc',line_status: true})}]
              }).then(res => {
                console.log('tim set group member custom field:',res)
              })
              this.$router.push({
                path: '/conference',
                query: {
                  groupId: id,
                  type: type == '10010' ? 1 : 0,
                }
              })
            }).catch(err => {
              console.error('tim set group member custom field:',err)
              ElMessage.error('会议已结束')
            })
          }
        }).catch(error=>{
          if(error.code === 10010){
            ElMessage.warning('会议已结束!')
            return
          }
          else if(error.code === 10007){
            tim.joinGroup({
              "groupID": id + ''
            }).then(res => {
              console.log('tim join group:',res)
              tim.setGroupMemberCustomField({
                groupID: id+'',
                memberCustomField: [{ key: 'line_status', value: JSON.stringify({bind_device: 'pc',line_status: true})}]
              }).then(res => {
                console.log('tim set group member custom field:',res)
              })
              this.$router.push({
                path: '/conference',
                query: {
                  groupId: id,
                  type: type == '10010' ? 1 : 0,
                }
              })
            }).catch(err => {
              console.error('tim set group member custom field:',err)
              ElMessage.error('会议已结束')
            })
          }
        })
      }).catch(error=>{
        if(error.code === 10010){
          ElMessage.warning('会议已结束!')
          return
        }
        else if(error.code === 10007){
          tim.joinGroup({
            "groupID": id + ''
          }).then(res => {
            console.log('tim join group:',res)
            tim.setGroupMemberCustomField({
              groupID: id+'',
              memberCustomField: [{ key: 'line_status', value: JSON.stringify({bind_device: 'pc',line_status: true})}]
            }).then(res => {
              console.log('tim set group member custom field:',res)
            })
            this.$router.push({
              path: '/conference',
              query: {
                groupId: id,
                type: type == '10010' ? 1 : 0,
              }
            })
          }).catch(err => {
            console.error('tim set group member custom field:',err)
            ElMessage.error('会议已结束')
          })
        }
      })

    },
    // 10001与10000顺序问题
    customOrder(messageList){
      for(let i = 0; i < messageList.length-1; i++) {
        if(messageList[i].payload?.extension === '10001' && messageList[i+1].payload?.extension === '10000'){
          let des1 = JSON.parse(messageList[i].payload.data)
          let des2 = JSON.parse(messageList[i+1].payload.data)
          if(des1.des.slice(19,-12) === des2.des){
            let temp  = messageList[i]
            messageList[i] = messageList[i+1]
            messageList[i+1] = temp
          }
        }
      }
      return messageList
    },
    // 聊天栏中对方的头像
    msgHeadImage(val){
      if(val.avatar?.slice(-4) === 'null'){ // 如果结尾为空，从保存的头像中获取
        for (let index = 0; index < this.loadHeadImage.length; index++) {
          if(this.loadHeadImage[index].userID == val.from) return this.loadHeadImage[index].avatar
        }
      }
      // return val.avatar
      return this.currentFriend.userProfile.avatar  // 直接使用当前好友的头像
    },
    // 会话列表里的头像
    userHeadImage(val){
      if(val.avatar?.slice(-4) === 'null'){ // 判断空头像，如果为空则从接口获取用户信息中的头像
        for (let index = 0; index < this.loadHeadImage.length; index++) {
          if(this.loadHeadImage[index].userID === val.userID) return this.loadHeadImage[index].avatar
        }
        memberMessage(val.userID).then((res)=>{
          val.avatar = staticPath + res.data.headImage
          this.loadHeadImage.push({ // 获取后进行保存
            'avatar': val.avatar,
            'userID': val.userID
          })
          return val.avatar
        })
      }
      else if(val.avatar === '/') return require('../../assets/images/square-avatar.png')
      else if(val.avatar[0] === '/' && val.avatar?.substr(-1) === '/') return require('../../assets/images/square-avatar.png')
      else return val.avatar
    },
    formatSeconds (value) {
      var secondTime = parseInt(value); // 秒
				var minuteTime = 0; // 分
				var hourTime = 0; // 小时
				if (secondTime >= 60) {
					minuteTime = parseInt(secondTime / 60);
					secondTime = parseInt(secondTime % 60);
					if (minuteTime >= 60) {
						hourTime = parseInt(minuteTime / 60);
						minuteTime = parseInt(minuteTime % 60);
					}
				}
				var result ="" +(parseInt(secondTime) < 10? "0" + parseInt(secondTime): parseInt(secondTime));

				// if (minuteTime > 0) {
					result ="" + (parseInt(minuteTime) < 10? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + ":" + result;
				// }
				// if (hourTime > 0) {
					result ="" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime): parseInt(hourTime)) +":" + result;
				// }
				return result;
    },
    onPlay(){ // 播放状态
      this.currentFriend.messages[this.soundIndex].soundStatus = require('@/assets/images/im/yuyinxiaoxi_playing.png')
    },
    onEnd(){  // 结束状态
      let mySound = true
      if(this.currentFriend.messages[this.soundIndex].from === this.currentFriendId){  // 判断消息发出的对象
        mySound = false
      }
      if(mySound){
        this.currentFriend.messages[this.soundIndex].soundStatus = require('@/assets/images/im/yuyinxiaoxi.png')
      }
      else{
        this.currentFriend.messages[this.soundIndex].soundStatus = require('@/assets/images/im/yuyinxiaoxi-333.png')
      }
      this.soundIndex = -1
    },
    onPause(){ // 暂停状态
      let mySound = true
      if(this.currentFriend.messages[this.soundIndex].from === this.currentFriendId){  // 判断消息发出的对象
        mySound = false
      }
      if(mySound){
        this.currentFriend.messages[this.soundIndex].soundStatus = require('@/assets/images/im/yuyinxiaoxi.png')
      }
      else{
        this.currentFriend.messages[this.soundIndex].soundStatus = require('@/assets/images/im/yuyinxiaoxi-333.png')
      }
    },
    async yuyinPlay (url,index) {
      let soundPlayer = document.getElementById('soundMsg');  // 根据id获取audio
      if(this.soundIndex !==-1){  //  判断是否正在播放
        this.onPause()
        if(url === soundPlayer.src){  // 相同则直接停止
          soundPlayer.src = ''
          soundPlayer.pause()
        }
        else{ // 不同则停止，再播放选中的url
          soundPlayer.pause()
          setTimeout(()=>{
            soundPlayer.src = url
            soundPlayer.play()
          },100)
        }
      }
      else{
        if(soundPlayer.paused){ // 判断是否暂停
          soundPlayer.src = url
          soundPlayer.play()
        }
      }
      this.soundIndex = index
    },
    strToObj (str) {  // 要对字符串进行判断
      try {
        return JSON.parse(str) // 如果不是json字符串就会抛异常
      } catch(e) {
          return '[图片]' // 旧的消息非json只有图片
      }
    },
    async checkAuthority(type){ // 判断是否有聊天权限 根据type判断后获取焦点
      if(this.messageUnlimited && this.messageTrigger == this.currentFriendId) return true // messageUnlimited是活动页咨询问题时不要会员可以聊天，但只能跟指定的人聊天
      else if(this.currentFriend?.type !== 'C2C'){
        // 群组或系统消息
        return true
      }
      this.btnDisabled = true // 防止接口返回前输入发送
      const [err , authority] = await to(checkMemberChat(this.currentFriendId,this.id))
      this.btnDisabled = false
      if(err){
        console.error('check member chat error:',err)
        ElMessage.warning('网络错误,请稍候再试!')
        return
      }
      if(!authority.data){
        this.$store.dispatch("message/hideIMWindow")
        this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
        return false
      }
      if(type === 'focus'){ // 获取焦点
        this.$refs.currentInput.focus()
      }
      else if(type === 'noFocus'){ // 获取焦点
        this.$refs.noSDKInput.focus()
      }
      return true
    },
    dateFormatToComplete,
    dateFormatToHour,
    getLocalTime,
    mendZero,
    async findCommunicateMsg (description) {
      let cuntomElem = {
        "des": description,
        "id": this.messageTrigger,
        "source": 'pc',
        "type": 10001
      }
      let message = tim.createCustomMessage({
        to: ''+this.messageTrigger,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: JSON.stringify(cuntomElem),
          extension: '10001' // 用于标识‘一键沟通’的类型
        }
      })
      tim.sendMessage(message).then(res => {
        this.currentFriend.messages.push(res.data.message)
        this.scrollMessageBlockToBottom()
        this.getFriendList()  // 获取一下好友列表，更新最新消息
      })
    },
    goVip () {
      this.hideWindow()
      routerWindow(this.$router,'/vip',{})
    },
    textareaKeyPress(e) { // 回车发送 阻止浏览器默认换行工作
      if (e.keyCode === 13) {
        this.sendText()
        e.preventDefault()
        return false;
      }
    },
    textareaKeyPress1(e) {
       // 回车发送留言
      if (e.keyCode === 13) {
        this.leaveText()
        e.preventDefault()
        return false;
      }
    },
    startSelectImage() {  // 从接口获取聊天许可
      if(!this.currentFriend?.able){
        this.$store.dispatch("message/hideIMWindow")
        this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
        return
      }
      this.$refs.imagePicker.click()
    },
    startSelectFile () {
      if(!this.currentFriend?.able){
        this.$store.dispatch("message/hideIMWindow")
        this.$store.commit('message/SET_IM_WINDOW_DIALOG', true)
        return
      }
      this.$refs.filePicker.click()
    },
    hideWindow() {
      if(this.shareMessage){
        this.shareWindow = true
        this.shareCancel = true
        this.shareHide = true
        return
      }
      this.$store.dispatch('message/hideIMWindow')
    },
    updateOnlineStatus() {  // 查看当前聊天用户是否在线
      if (this.imWindowVisible && this.currentFriendId !== null && this.currentFriend?.type === "C2C") {
        statusOne(this.currentFriendId).then(res => {
          this.currentFriendStatus = res.data
        }).catch((err) => {
          console.error('update user online status error:', err)
        })
      }
    },
    seeMore () {
      if (this.isSdkdata) { //腾讯云的加载更多
        let { nextReqMessageID, conversationID, messages } = this.currentFriend
        tim.getMessageList({ conversationID, nextReqMessageID, count: 20 }).then((res) => {
          if (res.data && res.data.messageList) {
            let arr = res.data.messageList
            arr = this.customOrder(arr) // 判断顺序
            messages.forEach(item => {
              arr.push(item)
            })
            this.currentFriend.messages = arr
            this.currentFriend.nextReqMessageID = res.data.nextReqMessageID
            this.currentFriend.isCompleted = res.data.isCompleted
          }
        })
      } else { //接口的加载更多
        this.pageNum ++;
        this.getMsgRecord();
      }

    },
    async switchToFriend(id, bool=true) { // bool-false：不查看对方在线状况
      if(id === 'system'){
        this.currentFriendId = id
        let userInfo = localStorage.getItem("userInfo");
        if(!userInfo) return  // 空判断
        userInfo = JSON.parse(localStorage.getItem("userInfos")).memberInfo
        let res = await unreadSystemNum(userInfo.id)
        this.systemMessageNum = (res && res.data) ? res.data : 0
        this.$store.dispatch("message/setSystemMessageNum",this.systemMessageNum) // 切换到系统消息后再获取剩余未读数
        let that = this
        setTimeout(()=>{
          that.scrollMessageBlockToBottom()
        },300)
        return
      }
      this.currentFriendId = id
      this.friends.find(val => {  // 找到id用户
        if (val.memberId == id){
          this.currentFriend = val // 当前聊天对象的信息
          return
        }
      })
      bool && this.updateOnlineStatus() // 查看对方是否在线
      if (this.isSdkdata) {
        // 拉取腾讯云的当前聊天对象的会话详情
        let { conversationID } = this.currentFriend
        await tim.getMessageList({  // 异步操作给 currentFriend 赋值
          conversationID, // 对应聊天人的userId,im有直接给出conversationID
          count: 20      // 一次拉取的聊天条数
        }).then(res => {
          let { messageList, isCompleted, nextReqMessageID } = res.data
          messageList = this.customOrder(messageList) // 判断顺序
          this.currentFriend.messages = messageList // 当前和聊天对象的消息列表
          this.currentFriend.messages.map((item)=>{
            if(item.avatar.length > 0 && item.avatar.indexOf(staticPath.slice(10)) === -1){ // 如果有头像但是没有默认前缀，则添加上去
              item.avatar = staticPath + item.avatar
            }
          })
          // 对语音消息状态进行绑定
          this.currentFriend.messages.map((item)=>{
            if(item.type ==='TIMSoundElem'){
              if(item.from === this.currentFriendId){
                item.soundStatus =  require('@/assets/images/im/yuyinxiaoxi-333.png')
              }
              else{
                item.soundStatus =  require('@/assets/images/im/yuyinxiaoxi.png')
              }
            }
          })
          this.currentFriend.isCompleted = isCompleted // 表示是否已经拉完所有消息
          this.currentFriend.nextReqMessageID = nextReqMessageID // 用于续拉，分页续拉时需传入该字段
          if(this.currentFriend.type === "GROUP") this.currentFriend.groupProfile.avatar = checkAvatar(this.currentFriend.groupProfile.avatar)
          else this.currentFriend.userProfile.avatar = checkAvatar(this.currentFriend.userProfile.avatar)
          // "TIMGroupTipElem"
        })
        // 设置为已读
        this.readed(id)
        setTimeout(() => {
          this.scrollMessageBlockToBottom()
        }, 300)
        if(this.shareMessage){
          if(!this.shareInfos){
            ElMessage.warning('请选择分享内容!')
          }
          else if(this.shareInfos.type === '10030' || this.shareInfos.type === '10031'){  // 分享动态
            this.shareWindow = true
            this.shareId = id
            if(!this.currentFriend){
              this.shareName = ''
            }
            else if(this.currentFriend.type === "C2C"){
              this.shareName = this.currentFriend.userProfile?.nick
            }
            else if(this.currentFriend.type === "GROUP"){
              this.shareName = this.currentFriend.groupProfile?.name
            }
          }
          else if(this.shareInfos.type === '10032'){  // 分享需求
            this.shareWindow = true
            this.shareId = id
            if(!this.currentFriend){
              this.shareName = ''
            }
            else if(this.currentFriend.type === "C2C"){
              this.shareName = this.currentFriend.userProfile?.nick
            }
            else if(this.currentFriend.type === "GROUP"){
              this.shareName = this.currentFriend.groupProfile?.name
            }
          }
          return
        }
      } else {
        this.getMsgRecord() // 接口查找聊天消息
      }
      if(this.currentFriend?.type === 'C2C'){
        const [err , authority] = await to(checkMemberChat(this.currentFriendId,this.id)) // 获取用户聊天许可 此许可用于图片文件发送按钮判断
        if(err){
          console.error("check member chat error:",err)
          return
        }
        this.currentFriend.able = authority.data
      }
      else{
        this.currentFriend.able = true
      }
    },
    getMsgRecord () {
      let params = {
        fromMemberId: this.imEasemobUsername,
        toMemberId: this.currentFriendId,
        offset: this.pageNum,
        limit: 20
      }
      getListLatelyMsgRecord(params).then((res) => {
        if (res.data.pageNum != 1) {
          this.currentFriend.messages = res.data.result.concat(this.currentFriend.messages)
        } else {
          this.currentFriend.messages = res.data.result
        }
        // 总共页数 > 当前页，说明没拉完
        this.currentFriend.isCompleted = (res.data.pages > res.data.pageNum ? false : true);
        if (res.data.result.length == 0) {
          this.currentFriend.isCompleted = true
        }
      })
    },
    async reLogin(){
      // 重新登录
      const res = await getUserSig({ account: this.myId }) //参数：我的id；获取签名，拿到userSig
      const { data } = res
      timLogin({ userID: this.myId.toString(), userSig: data })
      .finally(()=>{
        this.getFriendList()
      })
    },
    async initTIMConnection() {
      let res = await getUserSig({ account: this.myId }) //参数：我的id；获取签名，拿到userSig
      let that = this
      if(!res || !res.data){
        setTimeout(()=>{
          that.initTIMConnection() // token 过期 重新初始化
        },2000)
        return
      }
      let { data } = res
      timLogin({ userID: this.myId.toString(), userSig: data })
      if(this.notShowWindow) return
      function sdkReady(event){
        //收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        tim.on(TIM.EVENT.MESSAGE_RECEIVED, msgReceived);  // READY后再进行初始化
        console.log('tim sdk ready event:',event)
        that.$store.dispatch('tim/setSDK',true)
        that.getFriendList()
      }
      // 监听事件
      tim.on(TIM.EVENT.SDK_READY, sdkReady);
      function msgReceived(event){
        console.log('获取的新消息:',event);
        // * 如果不是在当前页面，则修改标题栏
        let isBackground = false  // 是否后台消息
        if(event?.data[0]?.payload?.extension === '20010'){
          // 后台转让主持人
          isBackground = true
        }
        if(!that.visibility && !that.newMessageTab.interval && !isBackground && !this.isTan){
          that.newMessageTab.time = 0
          that.newMessageTab.title = document.title
          that.newMessageTab.interval = setInterval(() => {
            that.newMessageTab.time++
            if(that.newMessageTab.time === 2) that.newMessageTab.time = 0
            if (that.newMessageTab.time % 2 == 0) {
              document.title = '【新消息】' + that.newMessageTab.title;
            } else {
              document.title = '【      】' + that.newMessageTab.title;
            }
          }, 500);
        }
        if(event.data[0].type === "TIMGroupTipElem" && event.data[0].to == that.currentFriendId && that.imWindowVisible){ // 群提示消息
          that.currentFriend.messages.push(event.data[0])
          that.readed(that.currentFriendId)
          that.switchToFriend(that.currentFriendId)
          setTimeout(() => {
            that.scrollMessageBlockToBottom()
          }, 300)
          return
        }
        else if(event.data[0].conversationID.includes("GROUP") && event.data[0].to == that.currentFriendId && that.imWindowVisible){
          that.currentFriend.messages.push(event.data[0])
          that.readed(that.currentFriendId)
          that.switchToFriend(that.currentFriendId)
          setTimeout(() => {
            that.scrollMessageBlockToBottom()
          }, 300)
          return
        }
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        if(event.data[0].from == that.currentFriendId && that.imWindowVisible){ // 如果是当前好友且聊天列表已经打开,则将消息push入当前好友聊天列表，并设定消息已读
          that.currentFriend.messages.push(event.data[0])
          that.readed(that.currentFriendId)
          setTimeout(() => {
            that.scrollMessageBlockToBottom()
          }, 300)
        }
        that.getFriendList(0)
        // actionType 为3的时候，并且这条信息是自己发布的,执行下音视频通话的挂断业务
        let latestMessage = that.strToObj(event.data[0].payload.data)
        if (latestMessage.actionType == 3 && event.data[0].from != that.currentFriendId) {
          tim.hangup()
        }
      }
      function sdkKickOut(event){
        // 收到被踢下线通知
        // event.data.type - 被踢下线的原因，例如:
        //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
        //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
        //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）
        // if(event.data.type === TIM.TYPES.KICKED_OUT_MULT_ACCOUNT){
        //   ElMessage.warning('WEB端同时只能登录一个帐号,正在退出登录!')
        //   localStorage.removeItem('postData')       // 退出时，清除草稿记录
        //   localStorage.removeItem('newMediasCover')
        //   localStorage.removeItem('newMediasData')
        //   localStorage.removeItem('finalDraft')     // 退出时，清除需求的草稿记录
        //   localStorage.removeItem('DynamicDraft')   // 退出时，清除动态的草稿记录
        //   localStorage.removeItem('showBread')      // 退出时，清除showBread
        //   localStorage.clear()
        //   that.$store.dispatch("user/logout").then(() => {
        //     that.$router.push('/');
        //   })
        //   that.$store.dispatch('user/logoutClosed')
        // }
        if(event.data.type === TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED){
          // 如果是token过期踢出，则重新登陆
          that.reLogin()
        }
        console.log('tim kick out event:',event)
      }
      tim.on(TIM.EVENT.KICKED_OUT, sdkKickOut);
      function netStatusChange(event){
        //  网络状态发生改变（v2.5.0 起支持）。
        // event.name - TIM.EVENT.NET_STATE_CHANGE
        // event.data.state 当前网络状态，枚举值及说明如下：
        //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
        //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
        //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
        console.log('tim net status change event:',event)
      }
      tim.on(TIM.EVENT.NET_STATE_CHANGE, netStatusChange);
    },
    getFriendList (type) { //获取会话列表
      if (type === 0) {
        this.updating = false
      } else {
        this.updating = true
      }
      //先拉取腾讯的sdk,若列表为空，请求后端的数据
      tim.getConversationList().then((data) => {
        console.log('tim get conversation list:',data)
        let { conversationList } = data.data
        if (conversationList.length === 0) {  // 先拉取腾讯的sdk,若列表为空
          this.isSdkdata = true;
          this.updating = false
          getlistLatelyContact().then((listResponse) => {
            // let tempList = this.friends // 防止将在线联系的好友删除
            let sum = tim.getTotalUnreadMessageCount();
            this.$store.dispatch("message/changeMessageCount",sum)  // 获取到未读消息后存入vuex及缓存中
            if (listResponse.data) {
              listResponse.data.forEach(item => {
                if (item.type == 'C2C') {
                  this.friends.push(item)
                }
              })
            }
            if(listResponse.data.length === 0 || !listResponse.data){ // 如果之前消息为空 则还是使用SDK
              this.isSdkdata = true
            }
            // 数组有重复数据，去重处理
            // this.friends = [...tempList,...this.friends]  // 将在线联系的好友放在前面
            this.friends = this.friends.filter((x, index) => {
              var temp = []
              this.friends.forEach(item => temp.push(item.memberId))
              return temp.indexOf(x.memberId) === index
            })
            this.updating = false
          }).catch(error=>{
            if(error.message !== '没有查询到个人信息'){
              console.error('get list lately contact error:',error);
            }
            else{
              this.updating = false
            }
          })
        } else { //若腾讯的sdk会话列表非空，直接获取数据
          this.isSdkdata = true;
          let sum = 0 // 记录未读消息总数
          conversationList.map(item => {
            if (item.type == 'C2C') {  // 2.2版本无需过滤客服  && item.userProfile.userID !== this.serviceId
              item.memberId = item.userProfile.userID
              item.unread_num = item.unreadCount
              sum+= item.unread_num
              let i = -1
              this.friends.find((friend,index)=>{ // 先更新一下最后一条消息防止无法显示
                if(friend.memberId === item.memberId && !friend.lastMessage && item.lastMessage){
                  i = index
                  return true
                }
              })
              if(i != -1) this.friends[i].lastMessage = item.lastMessage
              this.friends.push(item)
            }
            else if(item.type === "GROUP" && item.groupProfile.type === "Private"){
              item.memberId = item.groupProfile.groupID
              item.unread_num = item.unreadCount
              sum+= item.unread_num
              let i = -1
              this.friends.find((friend,index)=>{ // 先更新一下最后一条消息防止无法显示
                if(friend.memberId === item.memberId && !friend.lastMessage && item.lastMessage){
                  i = index
                  return true
                }
              })
              if(i != -1) this.friends[i].lastMessage = item.lastMessage
              this.friends.push(item)
            }
          })
          this.$store.dispatch("message/changeMessageCount",sum)  // 获取到未读消息后存入vuex及缓存中
          // 手动新添加的好友
          if (this.tempfriendObj) {
            this.friends.unshift(this.tempfriendObj)
          }
          // 数组有重复数据，去重处理
          this.friends = this.friends.filter((x, index) => {
            var temp = []
            this.friends.forEach(item => temp.push(item.memberId))
            return temp.indexOf(x.memberId) === index
          })
          this.friends.sort((a,b)=>{  // 根据最后一条排序
            if(!a || !b || !b.lastMessage || !a.lastMessage){ // 空判断
              return 0
            }
            return b.lastMessage.lastTime - a.lastMessage.lastTime
          })
          this.updating = false
          for (let index = 0; index < this.friends.length; index++) {
            const element = this.friends[index];
            if(element.type === "GROUP"){
              for (let j = 0; j < element.messages?.length; j++) {
                if(this.friends[index].messages[j].type === 'TIMGroupTipElem' && this.friends[index].messages[j].from === '@TIM#SYSTEM'){
                  if(!this.friends[index].messages[j]?.payload?.operatorID) continue
                  else if(this.friends[index].messages[j]?.payload?.operatorID === 'administrator'){
                    this.friends[index].messages[j].payload.oName = 'administrator'
                    continue
                  }
                  memberDetails(this.friends[index].messages[j]?.payload?.operatorID).then((r)=>{
                    if(this.friends[index].messages[j]) this.friends[index].messages[j].payload.oName = r.data.name
                  })
                }
                else continue
              }
            }
          }
        }
      })
    },
    // 消息已读
    readed(id) {
      const cId = this.currentFriend?.type === "GROUP" ? "GROUP" + id : "C2C" + id
      tim.setMessageRead({ conversationID: cId }).then(() => {
        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
        this.getFriendList(0)
      }).catch(err => {
        console.warn('setMessageRead error', err)
      })
    },
    scrollMessageBlockToBottom() { // 自动保持在底部
      let a = this.$refs.messageBlock
      let that = this
      setTimeout(function () {
        if(a){
          a.scrollTo(0, 1 << 20)
          if(that.friends?.length <= 1){
            return
          }
          that.friends.sort((a,b)=>{ // 排序
            if(!a || !b || !b.lastMessage || !a.lastMessage){ // 空判断
              return 0
            }
            return b.lastMessage.lastTime - a.lastMessage.lastTime
          })
        }
      }, 10)
    },
    async sendText() {  // 发送消息
      let res = await this.checkAuthority() // 等待获取接口后再进行判断
      if(!res) return
      if (this.currentFriend.inputText=== '' || this.currentFriend.inputText === undefined) {
        ElMessage.warning('请勿只输入空格或换行！')
        return false;
      }
      this.currentFriend.inputText = this.currentFriend.inputText.trim()
      // if (this.isVip === false && !this.messageUnlimited) {
      //   this.$store.dispatch("message/hideIMWindow")
      //   this.$store.dispatch("message/startQuickChat")
      //   return
      // }
      if (this.btnDisabled) return
      // setTimeout(() => {
      //   this.btnDisabled = false
      // },1000)
      this.btnDisabled = true

      this.currentFriendId = this.currentFriendId + ''
      let message = null
      const regArr = this.currentFriend.inputText.match(/@[^\s]+\s?/g)
      if(this.currentFriend.type === "GROUP" && regArr.length > 0){
        let arr = []
        this.atInfos.val.forEach((item)=>{
          const re = regArr.find(name=>name?.trim().slice(1) === item?.name?.trim())
          if(re){
            arr.push(item.id)
          }
        })
        message = tim.createTextAtMessage({
          to: this.currentFriendId + '',
          conversationType: TIM.TYPES.CONV_GROUP,
          payload: {
            text: this.currentFriend.inputText,
            atUserList: arr,
          },
        });
      }
      else{
        message = tim.createTextMessage({
          to: this.currentFriendId + '', //消息接收方的userID,字符串类型
          conversationType: this.currentFriend.type === "C2C" ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP, //类型：端到端会话
          payload: {
            text: this.currentFriend.inputText
          }
        })
      }
      tim.sendMessage(message).then(res => {
        if (!this.isSdkdata) { //如果此时数据都是从接口获取的
          let tempObj = {
            isSelf: true,
            elemType: 1,
            customElem: null,
            textElem: {
              msg: this.currentFriend.inputText
            }
          }
          this.currentFriend.messages.push(tempObj)
        } else {
          if(!this.currentFriend.messages || !this.currentFriend){  // 防止未定义
            this.switchToFriend(this.currentFriendId)
          }
          else{
            this.currentFriend.messages.push(res.data.message)
          }
        }
        this.btnDisabled = false
        this.currentFriend.inputText = ''
        this.scrollMessageBlockToBottom()
        // 发送完成后进行重置
        this.inputSelectStatus = {
          start: null,
          end: null,
        }
        this.atInfos = {
          num: 0,
          val: [],
        }
      })
      updateProfile()
    },
    getFileType(fileName) {
      var index= fileName.lastIndexOf(".");//获取最后一个.的位置
      var ext = fileName.substr(index+1);//获取后缀
      return ext
    },
    async sendFile () {
      let file = document.getElementById('filePicker').files
      if (file) {
        //判断文件格式
        var index= file[0].name.lastIndexOf(".");//获取最后一个.的位置
        var ext = file[0].name.substr(index+1);//获取后缀
        // var pattern = /^(doc|docx|xls|xlsx|txt|html|htm|zip|rar|pdf)$/;
        // if(!pattern.test(ext)) {
        //   ElMessage.warning('文件格式不支持！')
        //   return
        // }
        // var filename = file['name'];
        try {
          let res = await canUpload(file[0].name)
          if (res.data) {
            this.fileuploading = true;
            let message = tim.createFileMessage({
              to: this.currentFriendId + '',
              conversationType: this.currentFriend.type === "C2C" ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
              payload: {
                file: document.getElementById('filePicker')
              },
              onProgress: (event) => {
                console.log('file uploading:', event)
              }
            })
            message.payload.uuid = message.payload.uuid +'.'+ ext
            tim.sendMessage(message).then((res) => {
              if (!this.isSdkdata) { //如果此时数据都是从接口获取的
                let tempObj = {
                  isSelf: true,
                  elemType: 3,
                  customElem: null,
                  imageElem: {
                    path: staticPath + document.getElementById('filePicker')
                  }
                }
                this.currentFriend.messages.push(tempObj)
              } else {
                this.currentFriend.messages.push(res.data.message)
              }
              this.fileuploading = false
              this.scrollMessageBlockToBottom()
            }).catch(err => {
              this.fileuploading = false  // 上传文件按钮停止加载
              ElMessage.error(err)
            })
          } else {
            ElMessage.error('暂不支持此类型上传')
          }
        } catch (err) {
          console.error('send file error:',err)
        }
      }
    },
    async sendPicture() {
      let res = await this.checkAuthority() // 等待获取接口后再进行判断
      if(!res) return
      let pic = document.getElementById('imagePicker').files
      let picFormat = [ "image/gif","image/jpeg","image/jpg","image/png","image/svg"]
      if(!picFormat.includes(pic[0].type)){
        ElMessage.error('请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片')
        return
      }
      else if(pic[0].type === "image/jpeg" && pic[0].name.slice(-4)==='jfif'){
        ElMessage.error('请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片')
        return
      }
      this.uploading = true
      let message = tim.createImageMessage({
        to: this.currentFriendId + '',
        conversationType: this.currentFriend.type === "C2C" ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
        payload: {
          file: document.getElementById('imagePicker')
        },
        onProgress: (event) => {
          console.log('file uploading:', event)
        }
      })
      tim.sendMessage(message).then((res) => {
        if (!this.isSdkdata) { //如果此时数据都是从接口获取的
          let tempObj = {
            isSelf: true,
            elemType: 3,
            customElem: null,
            imageElem: {
              path: staticPath + document.getElementById('imagePicker')
            }
          }
          this.currentFriend.messages.push(tempObj)
        } else {
          this.currentFriend.messages.push(res.data.message)
        }
        this.uploading = false
        this.scrollMessageBlockToBottom()
      }).catch(err => {
        this.uploading = false
        ElMessage.error(err)
      })
    },
    showLargeImg(val){ // 图片大图
      this.imageMessage = true
      this.imageMsgURL = val
    },
    newImage(val,big = 0) { // 图片初始化 big=1则将图片放大
      if(!val) return // 无图则返回
      let w = val.payload.imageInfoArray[0].width
      let h = val.payload.imageInfoArray[0].height
      // 图片长宽为0，信息以JSON格式存在url中
      if(w === 0 || h === 0){
        let imgInfo = JSON.parse(val.payload.imageInfoArray[0].url)
        w = imgInfo[0].Width
        h = imgInfo[0].Height
        if(w>230){ h = h/(w/230);w = 230}
        if(big === 1){
          h*=1.5
          w*=1.5
        }
        return `<img style='width:${w}px;height:${h}px;' src='${imgInfo[0].URL}'/>`
      }
      if(w>230){ h = h/(w/230);w = 230}
      if(big === 1){
        h*=1.5
        w*=1.5
      }
        return `<img style='width:${w}px;height:${h}px;' src='${val.payload.imageInfoArray[0].imageUrl}'/>`
    },
    newVideo(val) { // 视频初始化
      return `<video style='width:230px;height:300px;' src='${val.payload.videoUrl}' controls='controls' />`
    }
  },
}
</script>

<style scoped lang="scss">
.myShipinIcon {
  width:20px;
  height: 19px;
  position: relative;
  top:3px;
  display: inline-block;
  background: url('../../assets/images/im/shipintonghua.png');
  background-size: 100% 100%;
}
.othersShipinIcon {
  width:20px;
  height: 19px;
  position: relative;
  top:3px;
  display: inline-block;
  background: url('../../assets/images/im/shipintonghua-333.png');
  background-size: 100% 100%;
}
.myYuyinIcon {
  width:23px;
  height: 22px;
  position: relative;
  top:4px;
  display: inline-block;
  background: url('../../assets/images/im/yuyintonghua.png');
  background-size: 100% 100%;
}
.othersYuyinIcon {
  width:23px;
  height: 22px;
  position: relative;
  top:4px;
  display: inline-block;
  background: url('../../assets/images/im/yuyintonghua-333.png');
  background-size: 100% 100%;
}
.shangla {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }
  span {
    font-size: 12px;
    color: #666;
  }
}
.badgePosition {
  position: absolute;
  top: -4px;
  right: -7px;
}
.im-container {
  z-index: 2003;
  position: fixed;
  box-shadow: 0 10px 10px rgba(107, 107, 107, 0.22);
  bottom: 10px;
  right: 10px;
  display: flex;
  width: 930px;
  // height: 900px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  .im-left-bar {
    width: 303px;
    height: 100%;
    background: #FBFBFB;
    .im-self-info {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $--color-theme;
      .img-avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
      .im-my-name {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 15px;
      }
      .vipIcon {
        margin-left: 7px;
        width: 24px;
        height: 23px;
      }
    }
    .im-self-info:active{
      cursor: move;
    }
    .friend-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      // height: 590px;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      .friend-card {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .friend-labels span {
          border: 1px solid #999;
          border-radius: 4px;
          padding: 0 3px;
          font-size: 12px;
          margin-left: 4px;
          color: #999;
          max-width: 60px;
          height: 18px;
          overflow: hidden;
        }
        .friend-avatar {
          position: relative;
          margin-left: 9px;
          border-radius: 50%;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }
      }
      .system-back{
        background: #D5E6F7;
      }
      .friend-card:hover {
        background: #d0d0d0;
        color: white;
      }
      .friend-card:hover .friend-labels span {
        border: white 1px solid;
        color: white;
      }
      .friend-card-s {
        background: #E6E9ED;
      }
      .friend-card-s * {
        color: #0F1C34;
      }
      .friend-card-s .friend-labels span {
        border: white 1px solid !important;
        color: #fff;
      }
    }
    .friend-list::-webkit-scrollbar {
      width: 0
    }
  }
  .im-group-tools{
    width: 300px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #666666;
    background-color: #ffffff;
  }
  .im-right-bar {
    width: 627px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    .title-bar {
      height: 60px;
      flex-shrink: 0;
      border-bottom: 1px rgba(107, 107, 107, 0.22) solid;
      font-size: 22px;
      color: #333333;
      display: flex;
      padding: 0 15px 0 30px;
      align-items: center;
      justify-content: space-between;
      .current-title{
        display: flex;
        align-items: center;
        .current-friend-avatar{
          display: flex;
          align-items: center;
          margin-right: 10px;
          img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        .current-friend-name{
          max-width: 300px;
          padding-right: 10px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .gotoOrgSpace{
          font-size: 18px;
          padding: 5px 10px;
          border-radius: 10px;
          margin-left: 10px;
          cursor: pointer;
          background: $--color-theme;
          color: white;
          text-align: center;
        }
      }
    }
    .title-bar:active{
      cursor: move;
    }
  }
}

.resizeNone {
  .el-textarea__inner {
    resize: none;
  }
}

.iconChatBox {
  background: #b5b3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 529px;
  .img {
    width: 300px;
    height: 420px;
    img {
      width: 300px;
      height: 370px;
    }
    .content {
      position: relative;
      top: -200px;
      z-index: 2;
      background: #fff;
      border-radius: 24px;
      padding: 10px 15px 70px 15px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      h3 {
        color: #4B2F08;
        font-size: 18px;
        padding: 0;
        margin: 0;
        text-align: center;
        font-weight: normal;
      }
      p {
        color: #7F6B4B;
        font-size: 15px;
        margin: 0;
        padding: 0;
        margin-top: 10px;
      }
      .btn {
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        width: 220px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 26px;
        color: #563B15;
        font-size: 17px;
        left: 50%;
        margin-left: -110px;
        background: #EBD692;
      }
    }
  }
}

.el-badge-friend {
  overflow: hidden;
  height: 20px;
  width: 64px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFang SC;
  font-weight: normal;
}

.friend-text {
  margin-left: 10px;
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 11px 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #666666;
}

.friend-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.friend-name {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  max-width: 120px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.friend-labels {
  display: flex;
}

.latest-message-time {
  font-size: 14px;
  padding-top: 2px;
  float: right;
  margin-top: -60px;
}

.latest-message {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: #8991A0 !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.latest-message-content {
  color: #8991A0 !important;
  display: inline-block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.close-button {
  cursor: pointer;
}

.message-block {
  overflow-y: scroll;
  flex-grow: 1;
  box-sizing: border-box;
  padding-bottom: 10px;
  .message-line {
    padding: 8px 20px;
    display: flex;
    position: relative;
    .p-t-30{
      padding-top: 30px !important;
    }
    .message-group-system-line{
      padding-top: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #B0B5C0;
      display: flex;
      justify-content: center;
      width: 100%;
      span{
        color: $--color-theme;
      }
    }
    .message-time-line{
      font-family: PingFang SC;
      font-weight: 400;
      color: #B0B5C0;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 5px;
      width: 100%;
      left: 0;
    }
  }
  .my-message {
    flex-direction: row-reverse;
  }
  .margin-time{
    margin-top: 20px;
  }
  .message-avatar {
    img {
      cursor: pointer;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
  .message-text {
    padding: 8px;
    margin-bottom: 5px;
    flex-grow: 0;
    max-width: 500px;
    border-radius: 8px;
    word-wrap: break-word;
    .system-title{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 5px;
    }
  }
}

.input-block {
  background: white;
  height: 210px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(107, 107, 107, 0.22);
}

.function-bar {
  width: 100%;
  height: 56px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  .emoji{
    display: inline-block;
    width: 27px;
    height: 27px;
    background: url('../../assets/images/emoji.png');
    background-size: 100% 100%;
  }
  .tupian {
    display: inline-block;
    width: 27px;
    height: 25px;
    background: url('../../assets/images/tupian.png');
    background-size: 100% 100%;
  }
  .type-text{
    font-family: PingFang;
    font-weight: 400;
    padding: 5px 0;
  }
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url('../../assets/images/file.png');
    background-size: 100% 100%;
  }
}


.function-item {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  cursor: pointer;
  font-size: 15px;
}

.function-item:hover {
  color: $--color-theme;
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url('../../assets/images/file-2.png');
    background-size: 100% 100%;
  }
  .tupian {
    background: url('../../assets/images/tupian-2.png');
    display: inline-block;
    width: 27px;
    height: 25px;
    background-size: 100% 100%;
  }
  .emoji{
    display: inline-block;
    width: 27px;
    height: 27px;
    background: url('../../assets/images/emoji-a.png');
    background-size: 100% 100%;
  }
}

.function-item div:last-child {
  font-size: 12px;
}

.function-icon {
  font-size: 25px;
}

.input-area {
  flex-grow: 1;
  padding: 0 10px;
}

.send-line {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  .el-button{
    background: $--color-theme;
    width: 80px;
    font-family: PingFang SC;
    font-weight: 450;
    color: #FFFFFF;
    line-height: 0px !important;
    font-size: 16px;
    border-radius: 4px;
  }
}
.custom-wrap {
  width: 100%;
  .custom {
    color: #202C3F;
    font-size: 18px;
    padding: 5px 10px;
    text-align: center;
    width: 400px;
    margin: auto;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 8px;
  }
}
.showtime {
  text-align: center;
  // background: red;
  .time {
    background: #b1b1b1;
    color: #E8E8E8;
    font-size: 12px;
    border-radius: 4px;
    padding: 0 6px;
  }
}
.others-message .message-text::after{
  content: '';
  position: absolute;
  left: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-right: 10px solid #f3f3f3;
  border-bottom: 5px solid transparent;
}
.others-message .message-text {
  line-height: 18px;
  margin-left: 10px;
  background: #F3F3F3;
  color: #333333;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  .unread-sign::after{
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    right: -10px;
    top: 5px;
  }
  .file {
    overflow: hidden;
    padding: 16px 17px 12px 17px;
    cursor: pointer;
    background: #fff;
    .l {
      float: left;
      span {
        display: block;
      }
      .title {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        color: #80899A;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 46px;
        height: 58px;
      }
      .doc {
        background: url('../../assets/images/file_icon-doc.png');
        background-size: 100% 100%;
      }
      .ppt {
        background: url('../../assets/images/file_icon-ppt.png');
        background-size: 100% 100%;
      }
      .txt {
        background: url('../../assets/images/file_icon-txt.png');
        background-size: 100% 100%;
      }
      .pdf {
        background: url('../../assets/images/file_icon-pdf.png');
        background-size: 100% 100%;
      }
      .excel {
        background: url('../../assets/images/file_icon-excel.png');
        background-size: 100% 100%;
      }
      .unknow {
        background: url('../../assets/images/file_icon-unknow.png');
        background-size: 100% 100%;
      }
    }
  }
  .box {
    overflow: hidden;
    padding: 12px 12px 8px 12px;
    cursor: pointer;
    border: 1px dashed #C4DAFF;
    .l {
      float: left;
      span {
        color: #80899A;
        font-size: 14px;
        display: block;
      }
      .title {
        color: #000;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .time {
        color: #333;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 50px;
        height: 50px;
        color: #fff;
        background: #1265F1;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .share-box{
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border: 2px dashed $--color-theme;
    border-radius: 4px;
    padding: 6px 10px;
    .l{
      display: flex;
      flex-direction: column;
      width: 160px;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .title{
        font-size: 18px;
        line-height: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #0F1C34;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .name{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991A0;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .expert{
      display: flex;
      width: 240px;
      flex-direction: column;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .top-info{
        display: flex;
        .name-info{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .top{
            display: flex;
            align-items: center;
            min-height: 30px;
            justify-content: space-between;
            width: 175px;
            .name{
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 18px;
              line-height: 20px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #0F1C34;
            }
            .position{
              max-width: 90px;
              overflow: hidden;
              white-space: nowrap;
              margin-left: 10px;
              height: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #54A7EE;
              background: #E7F4FE;
              border-radius: 2px;
              padding: 5px 10px;
            }
          }
          .belong{
            max-width: 180px;
            margin-top: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #8991A0;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
          }
        }
      }
      .direction{
        margin-top: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3C4351;
        width: 100%;
        height: 20px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .r{
      img{
        margin-top: 15px;
        width: 52px;
        height: 52px;
        border-radius: 6px;
      }
    }
  }
}
.my-message .message-text::after{
  content: '';
  position: absolute;
  right: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-left: 10px solid $--color-theme;
  border-bottom: 5px solid transparent;
}
.my-message .message-text {
  margin-right: 10px;
  line-height: 18px;
  background: $--color-theme;
  color: white;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  .file {
    overflow: hidden;
    padding: 16px 17px 12px 17px;
    cursor: pointer;
    background: #fff;
    .l {
      float: left;
      span {
        display: block;
      }
      .title {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        color: #80899A;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 46px;
        height: 58px;
      }
      .doc {
        background: url('../../assets/images/file_icon-doc.png');
        background-size: 100% 100%;
      }
      .ppt {
        background: url('../../assets/images/file_icon-ppt.png');
        background-size: 100% 100%;
      }
      .txt {
        background: url('../../assets/images/file_icon-txt.png');
        background-size: 100% 100%;
      }
      .pdf {
        background: url('../../assets/images/file_icon-pdf.png');
        background-size: 100% 100%;
      }
      .excel {
        background: url('../../assets/images/file_icon-excel.png');
        background-size: 100% 100%;
      }
      .unknow {
        background: url('../../assets/images/file_icon-unknow.png');
        background-size: 100% 100%;
      }
    }
  }
  .box {
    overflow: hidden;
    padding: 12px 12px 8px 12px;
    border: 1px dashed #FFFFFF;
    cursor: pointer;
    .l {
      float: left;
      span {
        color: #fff;
        font-size: 14px;
        display: block;
      }
      .title {
        color: #fff;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .time {
        color: #fff;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 10px;
      span{
        display: inline-block;
        width: 50px;
        height: 50px;
        color: #fff;
        background: #1265F1;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .share-box{
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border: 2px dashed $--color-theme;
    border-radius: 4px;
    padding: 6px 10px;
    .l{
      display: flex;
      flex-direction: column;
      width: 160px;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #0F1C34;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-height: 20px;
        -webkit-box-orient: vertical;
      }
      .name{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991A0;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .expert{
      display: flex;
      width: 240px;
      flex-direction: column;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .top-info{
        display: flex;
        .name-info{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .top{
            display: flex;
            align-items: center;
            min-height: 30px;
            justify-content: space-between;
            width: 175px;
            .name{
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-height: 20px;
              -webkit-box-orient: vertical;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #0F1C34;
            }
            .position{
              max-width: 90px;
              overflow: hidden;
              white-space: nowrap;
              margin-left: 10px;
              height: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #54A7EE;
              background: #E7F4FE;
              border-radius: 2px;
              padding: 5px 10px;
            }
          }
          .belong{
            max-width: 180px;
            margin-top: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #8991A0;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
          }
        }
      }
      .direction{
        margin-top: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3C4351;
        width: 100%;
        height: 20px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .r{
      img{
        margin-top: 15px;
        width: 52px;
        height: 52px;
        border-radius: 6px;
      }
    }
  }
}
.message-container{
  display: flex;
  position: relative;
}
.my-message .message-time{
  min-width: 127px;
  font-size: 12px;
  text-align: end;
  padding-right: 10px;
  position: absolute;
  right: 0;
  bottom: -11px;
}
.others-message .message-time{
  min-width: 127px;
  font-size: 12px;
  text-align: end;
  padding-right: 10px;
  position: absolute;
  left: 10px;
  bottom: -11px;
}
.others-message .box-live{
  margin-left: 10px;
}
.my-message .box-live{
  margin-right: 10px;
}

.box-live{
  overflow: hidden;
  cursor: pointer;
  padding: 12px 12px 8px 12px;
  border: 2px dashed $--color-theme;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  .l {
    float: left;
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px;
      display: block;
      padding-right: 20px;
    }
    .title{
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: black;
    }
    .time-title{
      padding-top: 5px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #80899A;
    }
    .time{
      padding-top: 5px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: black;
    }
  }
  .r {
    float: right;
    margin-left: 10px;
    span{
      display: inline-block;
      width: 50px;
      height: 50px;
      color: #fff;
      background: $--color-theme;
      border-radius: 5px;
      text-align: center;
      line-height: 50px;
    }
  }
}
::v-deep .el-dialog{
  background-color: rgba(255,255,255,0);
}
::v-deep .el-dialog__header{
  background: #FFFFFF;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
:deep() .el-dialog__body{
  background: #FFFFFF !important;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  max-height:  60vh;
  overflow-y: auto;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
:deep() .el-dialog__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.share-name{
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #111C32;
  line-height: 35px;
}
.share-buttons{
  border-top: 1px solid #E5E7EC;
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: row-reverse;
  .confirm{
    background: #E68136;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    margin-left: 20px;
  }
}
.share-message{
  background: #ffffff !important;
  box-shadow: 0px 1px 4px 0px rgba(15, 28, 52, 0.3);
}
.share-message::after{
  content: '';
  border: none !important;
}
.share-infos{
  max-height: 60px;
  background: #F7F8F9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  .words{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3C4351;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.emoji-list{
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  .emoji{
    width: 24px;
    height: 24px;
    margin: 8px;
    cursor: pointer;
    &:hover{
      background-color: #999999;
    }
  }
}
</style>

<style scoped>
  .el-badge__content.is-fixed.is-dot {
    right: 9px;
    top: 5px;
  }
</style>
