<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'410px'" :custom-class="'dialog-no-title dialog-align-center dialog-full-transparent'">
    <div class="ticket-receive-pop" :style="{ '--bkImage': `url('${imageUrl}')`}">
      <p class="word">狂欢—探角优惠大放送</p>
      <div :class="{'ticket-line-1': item.vipType === 13,'ticket-line': item.vipType !== 13}" v-for="item in ticketList" :key="item.couponCode">
        <div class="left" v-if="item.couponType === 1">
          {{ item.couponContent }}<span>折</span>
        </div>
        <div class="left" v-else-if="item.couponType === 2">
          {{ item.couponContent }}<span>元</span>
        </div>
        <div class="left" v-else-if="item.couponType === 3">
          {{ item.couponContent }}<span>天</span>
        </div>
        <div class="right">
          <p class="p-0 text-ol-ell">{{ item.couponName }}</p>
          <p class="p-1 text-ol-ell" v-if="item.couponType === 1">直降金额<span>{{ calculateMinusPrice(item) }}</span>元</p>
          <p class="p-1 text-ol-ell" v-if="item.couponType === 2">直降金额<span>{{ item.couponContent }}</span>元</p>
        </div>
      </div>
      <div class="ticket-receive-button-line">
        <button class="ticket-receive-button" @click="receiveTicket">开心收下</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTanProducts } from "@/api/Tan/product";
import { receiveTanTicket, } from "@/api/Tan/ticket";
import { ElMessage, } from 'element-plus';


export default{
  data(){
    return {
      show: false,
      products: [],
      loading: false,
    }
  },
  computed:{
    ...mapGetters([
      "TanTicketReceivePopShow",
    ]),
    imageUrl(){
      if(this.TanTicketReceivePopShow){
        return JSON.parse(this.TanTicketReceivePopShow).img
      }
      else{
        return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/10/11/1731292457151827'
      }
    },
    ticketList(){
      if(this.TanTicketReceivePopShow){
        return JSON.parse(this.TanTicketReceivePopShow).tickets
      }
      else{
        return []
      }
    },
  },
  watch:{
    TanTicketReceivePopShow(val){
      if(val) this.show = true
      else this.show = false
    },
    show(val){
      if(!val){
        this.$store.dispatch('Tan/setTicketReceivePopShow','')
      }
    },
  },
  mounted(){
    this.getProducts()
  },
  methods:{
    getProducts(){
      getTanProducts().then(res=>{
        this.products = res.data.result
      })
    },
    calculateMinusPrice(item){
      if(!item.vipType){
        return '-'
      }
      const product = this.products.find(jtem=>jtem.vipType === item.vipType)
      let price = 0
      if(!product.extendData) price = product.salePrice
      else{
        if(item.vipType === 12){
          price = Math.round((product.salePrice + product.extendData.eachCountPrice * product.extendData.vipMinCount + product.extendData.subscribeMinCount * product.extendData.eachSubscribePrice) * 100) / 100
        }
        else{
          price = Math.round((product.salePrice + product.extendData.eachCountPrice * product.extendData.vipMinCount) * 100) / 100
        }
      }
      return Math.round((price - price * Number(item.couponContent) / 10) * 100) / 100
    },
    receiveTicket(){
      // 领取优惠券
      if(this.loading) return
      this.loading = true
      receiveTanTicket(this.ticketList.map(item=>item.couponCode)).then(()=>{
        ElMessage({
          message: '兑换成功',
          type: 'success',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 5000,
        })
        setTimeout(()=>{
          this.show = false
        },1000)
      }).finally(()=>{
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ticket-receive-pop{
  width: 100%;
  height: 468px;
  box-sizing: border-box;
  padding-top: 156px;
  background-image: var(--bkImage);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .word{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 25px;
    background: linear-gradient(90deg, #FFFFFF 0%, #FFE4C8 100%);
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(0px 2px 1px rgba(208,16,16,0.5));
    line-height: 36px;
    box-sizing: border-box;
    width: 100%;
    padding-left: 117px;
    padding-bottom: 9px;
    position: relative;
  }
  .ticket-line{
    width: 313px;
    height: 74px;
    margin: 16px 48px;
    background-image: url('../../../assets/images/Tan/pop/ticket/ticket.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      width: 85px;
      display: flex;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 48px;
      color: #EC3E37;
      line-height: 74px;
      margin-top: 10px;
      white-space: nowrap;
      span{
        padding-top: 10px;
        font-size: 14px;
      }
    }
    .right{
      width: calc(100% - 105px);
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #EC3E37;
        line-height: 22px;
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #863811;
        line-height: 22px;
        span{
          color: #350705;
        }
      }
    }
  }
  .ticket-line-1{
    width: 313px;
    height: 74px;
    margin: 16px 48px;
    background-image: url('../../../assets/images/Tan/pop/ticket/ticket-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      width: 85px;
      display: flex;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 48px;
      color: #EC3E37;
      line-height: 74px;
      margin-top: 10px;
      white-space: nowrap;
      span{
        padding-top: 10px;
        font-size: 14px;
      }
    }
    .right{
      width: calc(100% - 105px);
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #EC3E37;
        line-height: 22px;
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #863811;
        line-height: 22px;
        span{
          color: #350705;
        }
      }
    }
  }
  .ticket-receive-button{
    margin: 5px auto 0;
    width: 124px;
    height: 40px;
    background: linear-gradient( 81deg, #FFFCF8 0%, #FDD5AD 100%);
    box-shadow: 0px 2px 4px 0px rgba(159,8,8,0.5);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #72300F;
  }
  .ticket-receive-button-line{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>