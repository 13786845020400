<template>
  <header class="header" :style="`margin-left: -${scrollLeft}px;`">
    <el-dialog v-model="showService" :show-close="false" :close-on-press-escape="false" :width="'660px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
      <div class="cor-window">
        <p class="p-0">预约专属客服</p>
        <img src="@/assets/images/Tan/project/close-button.png" @click="closeWindow" class="icon"/>
        <div class="form-item">
          <p class="p-1"><span>*</span>企业名称：</p>
          <!-- <el-input v-model="form.companyName" maxlength="20" placeholder="请输入企业名称" class="input" readonly></el-input> -->
          <p class="name text-ol-ell">{{ form.companyName }}</p>
        </div>
        <div class="form-item">
          <p class="p-1"><span>*</span>职位：</p>
          <el-select v-model="form.position" style="width: 376px;" placeholder="请选择" clearable >
            <el-option
              v-for="(item,index) in positionList"
              :key="index"
              :label="item"
              :value="item"/>
          </el-select>
        </div>
        <div class="form-item">
          <p class="p-1"><span>*</span>联系电话：</p>
          <el-input @input="handlePhoneChange" v-model="form.phone" placeholder="请输入手机号码" class="input" maxlength="11" />
        </div>
        <div class="buttons">
          <button @click="confirm" class="confirm" v-loading="loading">提交</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="trialDialogVisible" :custom-class="'dialog-no-title dialog-align-center'" :show-close="false" :width="'338px'" :close-on-click-modal="false" center>
      <div class="ticket-trial-dialog">
        <p class="ticket-dialog-name">{{ ticketSelected.couponTypeName }}</p>
        <p class="ticket-dialog-time">有效期为 <span>{{ ticketSelected.couponContent + '天' }}</span>，<br/>是否确认使用？</p>
        <div class="buttons">
          <button @click="cancelTrial" class="cancel">取消</button>
          <button @click="confirmTrial" class="confirm">确定</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="exchangeDialogVisible" :custom-class="'dialog-no-title dialog-align-center'" :show-close="false" :width="'338px'" :close-on-click-modal="false" center>
      <div class="ticket-trial-dialog">
        <p class="ticket-dialog-name">兑换优惠券</p>
        <input v-model="exchangeInput" class="ticket-exchange-input" placeholder="请输入兑换码"/>
        <div class="buttons">
          <button @click="cancelExchange" class="cancel">取消</button>
          <button @click="confirmExchange" class="confirm" v-loading="exchangeLoading">确定</button>
        </div>
      </div>
    </el-dialog>
    <div class="left">
      <img src="@/assets/images/Tan/identity/logo.png" class="logo" @click="toHomePage"/>
      <p class="p-0" @click="backToHome" v-if="$route.path !== '/p-center'">首页</p>
      <div class="p-1" @click="backToLast" v-if="$route.path !== '/p-center'">
        <p>返回上一页</p>
        <img src="@/assets/images/Tan/navbar/back-last.png" />
      </div>
      <!-- <p class="p-1"><span @click="backTeamApply">团队管理</span>/成员加入审批</p> -->
    </div>
    <div class="right">
      <el-popover
        ref="ticketList"
        placement="bottom"
        :width="374"
        trigger="hover"
        @show="getTicketCompanyList"
        :popper-class="'popper-class-user'">
        <template #reference>
          <img src="@/assets/images/Tan/navbar/ticket.png" class="pop-ticket"/>
        </template>
        <div class="ticket-window">
          <el-popover
            ref="ticketTypeSelection"
            placement="bottom-start"
            :width="105"
            trigger="hover"
            :appendToBody="false"
            :popper-class="'popper-class-user'">
            <template #reference>
              <div class="ticket-title-line">
                <p class="ticket-title">{{ ticketType.name }}</p>
                <img src="@/assets/images/Tan/navbar/down.png" class="ticket-down"/>
              </div>
            </template>
            <div class="ticket-type-option-wrap">
              <div class="ticket-type-option" v-for="(item,index) in ticketTypeList" :key="index" @click="selectTicketType(index)" :class="{'ticket-type-active': item.value === 1}">
                {{ item.name }}
              </div>
            </div>
          </el-popover>
          <div class="ticket-wrap hide-scroll-bar" v-loading="ticketCompanyListLoading">
            <div class="ticket-line" :class="{'type-0': item.couponType === 1,'type-1': item.couponType === 2,'type-2': item.couponType === 3}" v-for="(item,) in ticketCompanyList" :key="item.couponCode">
              <img src="@/assets/images/Tan/navbar/new-icon.png" class="new-icon" v-if="item.newStatus && item.couponStatus === 1"/>
              <div class="left">
                <div class="t-0" v-if="item.couponType === 1">
                  {{ item.couponContent }}<span>折</span>
                </div>
                <div class="t-1" v-else-if="item.couponType === 2">
                  <span>￥</span>{{ item.couponContent }}
                </div>
                <div class="t-0" v-if="item.couponType === 3">
                  {{ item.couponContent }}<span>天</span>
                </div>
              </div>
              <img src="@/assets/images/Tan/navbar/divide.png" class="divide"/>
              <div class="right">
                <div class="info">
                  <div class="name text-ol-ell">
                    {{ item.couponTypeName || '-' }}
                  </div>
                  <div class="n-type text-ol-ell">
                    {{ item.couponName || '-' }}
                  </div>
                  <div class="time text-ol-ell">
                    有效期至：{{ item.expireTime ? item.expireTime.slice(0,3).map((jtem)=>jtem >= 10 ? jtem : '0' + jtem).join('.') : '-' }}
                  </div>
                </div>
                <div class="button" @click="selectTicket(item)" v-if="item.couponStatus === 1">立即使用</div>
                <div class="button g-red"  v-else-if="item.couponStatus === 2">已使用</div>
                <div class="button g-red"  v-else-if="item.couponStatus === 4">使用中</div>
                <div class="button gray"  v-else>已过期</div>
              </div>
            </div>
            <el-empty v-if="ticketCompanyList?.length === 0" description="暂无优惠券" style="padding: 0;"></el-empty>
          </div>
          <p class="ticket-tip" @click="openExchange">兑换优惠券</p>
        </div>
      </el-popover>
      <div class="update" @click="openVip">
        <img src="@/assets/images/Tan/navbar/rocket.png" class="rocket"/>
        <p>升级</p>
      </div>
      <img src="@/assets/images/Tan/navbar/invite.png" style="width: 20px;height: 20px;margin-left: 23px;cursor: pointer;" @click="showInvite"/>
      <el-popover
        placement="bottom"
        :width="428"
        trigger="hover"
        :popper-class="'popper-class-user'">
        <template #reference>
          <img src="@/assets/images/Tan/navbar/host.png" class="pop-image"/>
        </template>
        <div class="message-window">
          <p class="tip">全部标记已读</p>
          <div class="title-line">
            <div class="option" :class="{'active': messageCur === 0}" @click="changeMessageCur(0)">
              未读通知
            </div>
            <div class="option" :class="{'active': messageCur === 1}" @click="changeMessageCur(1)">
              全部通知
            </div>
          </div>
          <div class="message-container">
            <div class="empty">
              <img src="@/assets/images/Tan/navbar/message-empty.png"/>
              <p>暂无通知</p>
            </div>
          </div>
        </div>
      </el-popover>
      <el-popover
        placement="bottom"
        :width="262"
        trigger="hover"
        :popper-class="'popper-class-user'">
        <template #reference>
          <img src="@/assets/images/Tan/navbar/service.png" class="pop-image" style="width: 20px;height: 22px;"/>
        </template>
        <div class="service-window">
          <div class="line" @click="service(0)">
            <div class="icon">
              <img src="@/assets/images/Tan/navbar/icon-0.png"  style="width: 14px;height: 16px;"/>
            </div>
            <p>使用咨询</p>
            <img src="@/assets/images/Tan/navbar/right.png" class="right"/>
          </div>
          <div class="line" @click="service(1)">
            <div class="icon">
              <img src="@/assets/images/Tan/navbar/icon-1.png"  style="width: 16px;height: 16px;"/>
            </div>
            <p>购买咨询</p>
            <img src="@/assets/images/Tan/navbar/right.png" class="right"/>
          </div>
          <div class="line" @click="service(2)">
            <div class="icon">
              <img src="@/assets/images/Tan/navbar/icon-2.png"  style="width: 14px;height: 15px;"/>
            </div>
            <p>预约专属客服</p>
            <img src="@/assets/images/Tan/navbar/right.png" class="right"/>
          </div>
        </div>
      </el-popover>
      <el-popover
        @show="userPopShow"
        placement="bottom"
        :width="374"
        trigger="hover"
        :popper-class="'popper-class-user'">
        <template #reference>
          <div class="my-avatar">
            <MyAvatar :src="TanInfo.img" :id="TanInfo.tjMemberId" :middle="true"></MyAvatar>
          </div>
        </template>
        <div class="user-window">
          <div class="top">
            <div class="avatar">
              <MyAvatar :src="TanInfo.img" :id="TanInfo.tjMemberId"></MyAvatar>
            </div>
            <div class="right">
              <p class="name text-ol-ell">{{ TanInfo.name }}</p>
              <p class="phone text-ol-ell">{{ TanInfo.phone }}</p>
            </div>
          </div>
          <div class="company-info">
            <p class="name">{{ TanInfo.tjCompanyName }}</p>
            <p class="num" v-if="vipInfo?.length > 0">享有专家数据和管理权限的成员数量 {{ vipNumShow }}</p>
            <p class="num" v-else>免费版</p>
            <div class="line">
              <div @click="update" class="update">
                <p>升级版本</p>
                <img src="@/assets/images/Tan/order/update.png" />
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="button" @click="clickUser(0)">
              <div class="icon">
                <img src="@/assets/images/Tan/navbar/icon-3.png"  style="width: 18px;height: 18px;"/>
              </div>
              <p>账号设置</p>
            </div>
            <div class="button" @click="clickUser(1)">
              <div class="icon">
                <img src="@/assets/images/Tan/navbar/icon-4.png"  style="width: 14px;height: 15px;"/>
              </div>
              <p>订单管理</p>
            </div>
            <div class="button" @click="clickUser(2)">
              <div class="icon">
                <img src="@/assets/images/Tan/navbar/icon-5.png"  style="width: 18px;height: 15px;"/>
              </div>
              <p>团队管理</p>
            </div>
            <div class="button" @click="clickUser(3)">
              <div class="icon">
                <img src="@/assets/images/Tan/navbar/icon-6.png"  style="width: 16px;height: 16px;"/>
              </div>
              <p>探角官网</p>
            </div>
          </div>
          <div class="bottom">
            <button @click="commitLogout">退出登录</button>
          </div>
        </div>
      </el-popover>
    </div>
  </header>
</template>

<script>
import { routerWindow,setTanVipPop } from '@/utils/tool';
import MyAvatar from './MyAvatar.vue';
import { getTanLocalInfo,removeTanLocalInfo } from "@/utils/Tan/login";
import { getTanCompanyTicketList,receiveTanTicket,useTanTicketByCode, } from "@/api/Tan/ticket";
import { setCookie } from "@/utils/Cookies/index";
import { ElMessage, ElMessageBox } from 'element-plus';
import { mapGetters } from 'vuex';
import { activityRegister } from "@/api/activity";
import { getSelectFatherSonByType } from "@/api/config";

export default{
  components:{
    MyAvatar,
  },
  data(){
    return{
      trialDialogVisible: false,
      trialTicketLoading: false,
      exchangeDialogVisible: false,
      exchangeLoading: false,
      exchangeInput: '',
      ticketSelected: {
      },
      ticketTypeList:[
        {
          name: '全部优惠券',
          couponType: -1,
          value: 1,
        },
        {
          name: '限时折扣券',
          couponType: 1,
          value: 0,
        },
        {
          name: '现金抵扣券',
          couponType: 2,
          value: 0,
        },
        {
          name: '试用体验券',
          couponType: 3,
          value: 0,
        },
      ],
      ticketCompanyListLoading: false,
      ticketCompanyList: [],
      vipInfo: [],
      loading: false,
      teamApply: false,
      TanInfo: {},
      scrollLeft: 0,
      metaName: '首页',
      messageCur: 0,
      showService: false,
      positionList: [],
      form:{
        companyName: '',
        position: '',
        phone: '',
      }
    }
  },
  computed:{
    ...mapGetters([
      "TanVipInfo",
      "TanVipPopShow",
      "TanPersonalEditPopShow",
      "TanTeamManagementApply",
    ]),
    ticketType(){
      return this.ticketTypeList.find(item=>item.value === 1)
    },
    vipNumShow(){
      if(this.vipInfo?.length === 0) return ''
      const flag1 = this.vipInfo.find(item=>item.vipType === 13 && item.useStatus)
      const flag2 = this.vipInfo.find(item=>item.vipType === 12 && item.useStatus)
      if(flag1){
        return flag1.orderExtendData?.vipCount || 5
      }
      else if(flag2){
        return flag2.orderExtendData?.vipCount || 20
      }
      else return ''
    },
    vipLength(){
      return this.TanVipInfo?.length
    },
  },
  watch:{
    vipLength(){
      this.getVipInfo()
    },
    TanVipPopShow(val){
      if(val === -1){
        this.getVipInfo()
      }
    },
    showService(val){
      if(val){
        this.getSelection()
      }
    },
    TanTeamManagementApply(val){
      if(val){
        this.teamApply = true
      }
      else{
        this.teamApply = false
      }
    },
    TanPersonalEditPopShow(val){
      if(!val){
        this.TanInfo = getTanLocalInfo()?.tjInfo || {}
      }
    },
    $route(to){
      this.metaName = to.meta.metaName || '首页'
      this.userPopShow()
    },
  },
  mounted(){
    this.metaName = this.$route.meta.metaName || '首页'
    this.userPopShow()
    this.setScroll()  // 设置横向滚动
  },
  methods:{
    getTicketCompanyList(){
      if(this.ticketCompanyListLoading){
        return
      }
      let param = {
        "limit": 45,
        "offset": 1,
        "queryData": {
        }
      }
      if(this.ticketType.name !== '全部优惠券' && this.ticketType.couponType){
        param.queryData.couponType = this.ticketType.couponType
      }
      this.ticketCompanyListLoading = true
      getTanCompanyTicketList(param).then(res=>{
        this.ticketCompanyList = res.data.result
      }).finally(()=>{
        this.ticketCompanyListLoading = false
      })
    },
    openExchange(){
      this.exchangeDialogVisible = true
      if(!this.$refs.ticketList) return
      this.$refs.ticketList.hide()
    },
    cancelExchange(){
      this.exchangeDialogVisible = false
      this.exchangeInput = ''
    },
    confirmExchange(){
      // 兑换优惠券
      if(this.exchangeLoading) return
      else if(this.exchangeInput?.length === 0){
        ElMessage({
          message: '请输入兑换码',
          type: 'error',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 3000,
        })
        return
      }
      this.exchangeLoading = true
      receiveTanTicket([this.exchangeInput]).then(()=>{
        ElMessage({
          message: '兑换成功',
          type: 'success',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 5000,
        })
        this.cancelExchange()
      }).catch(()=>{
        ElMessage({
          message: '输入的兑换码无效',
          type: 'error',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 5000,
        })
      }).finally(()=>{
        this.exchangeLoading = false
      })
    },
    cancelTrial(){
      this.trialDialogVisible = false
      this.ticketSelected = {}
    },
    confirmTrial(){
      // 使用优惠券
      if(!this.ticketSelected.couponCode || this.trialTicketLoading) return
      this.trialTicketLoading = true
      useTanTicketByCode(this.ticketSelected.couponCode).then(()=>{
        ElMessage({
          message: '使用成功',
          type: 'success',
          customClass: 'message-custom-no-width message-custom-plain message-custom-16 message-custom-333',
          duration: 5000,
        })
        this.cancelTrial()
      }).finally(()=>{
        this.trialTicketLoading = false
      })
    },
    calcTicketTime(time){
      let newDate = new Date();
      newDate.setDate(newDate.getDate() + time);
      const newYear = newDate.getFullYear()
      const newMonth = newDate.getMonth() + 1 < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
      const newDay = newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate()
      return newYear + '-' + newMonth + '-' + newDay + ' '+ '23:59'
    },
    selectTicket(item){
      // 选中优惠券
      if(item.couponType === 3){
        // 试用体验
        this.trialDialogVisible = true
        this.ticketSelected = item
      }
      else{
        // 保存使用的优惠券并打开支付窗口
        this.$store.dispatch('Tan/setTicketSelectedInfo',item)
        if(item.vipType === 12){
          setTanVipPop(1)
        }
        else if(item.vipType === 13){
          setTanVipPop(6)
        }
      }
      if(!this.$refs.ticketList) return
      this.$refs.ticketList.hide()
    },
    selectTicketType(index){
      for (let i = 0; i < this.ticketTypeList.length; i++) {
        this.ticketTypeList[i].value = 0
        if(i === index) this.ticketTypeList[i].value = 1
      }
      if(!this.$refs.ticketTypeSelection) return
      this.$refs.ticketTypeSelection.hide()
      this.getTicketCompanyList()
    },
    backToHome(){
      if(this.$route.path !== '/p-center'){
        this.$router.push('/p-center')
      }
    },
    backToLast(){
      if(this.$route.path === '/team-management' && this.teamApply){
        this.$store.dispatch('Tan/setTeamApply',false)
      }
      else{
        this.$router.go(-1)
      }
    },
    getVipInfo(){
      this.vipInfo = this.TanVipInfo
    },
    getSelection(){
      getSelectFatherSonByType(10034).then(res=>{
        this.positionList = res.data.map(item=>item.val)
        this.form = {
          companyName: getTanLocalInfo()?.tjInfo?.tjCompanyName,
          position: getTanLocalInfo()?.tjInfo?.duties,
          phone: getTanLocalInfo()?.tjInfo?.phone,
        }
      })
    },
    clearForm(){
      this.form = {
        companyName: '',
        position: '',
        phone: '',
      }
    },
    confirm(){
      if(!this.form.companyName){
        ElMessage.warning('请输入企业名称')
        return
      }
      else if(!this.form.position){
        ElMessage.warning('请选择职务')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入手机号码')
        return
      }
      this.loading = true
      const param = {
        activityName: '探角预约专属客服',
        activityType: '50',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: this.form.companyName,
        name: this.TanInfo.name || '-',
        position: this.form.position,
        jsonStr: JSON.stringify({
        }),
      }
      activityRegister(param).then(()=>{
        ElMessage.success('提交成功')
        this.closeWindow()
        this.clearForm()
      }).finally(()=>{
        this.loading = false
      })
    },
    backTeamApply(){
      this.$store.dispatch('Tan/setTeamApply',false)
    },
    commitLogout(){
      ElMessageBox.confirm("是否确认退出登录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        lockScroll: false,  // 防止页面振动
        type: "warning",
      }).then(() => {
        ElMessage.success('成功退出登录!')
        removeTanLocalInfo()
        setCookie('KqdInfo',{status: '0'})
        this.$store.dispatch('Tan/setLoginChange','logout')
        this.$router.push({
          path: '/' // 返回首页
        })
      })
    },
    clickUser(index){
      switch (index) {
        case 0:
          this.openPersonalEditPop()
          break;
        case 1:
          this.$router.push('/order')
          break;
        case 2:
          this.$router.push('/team-management')
          break;
        case 3:
          this.$router.push('/')
          break;
        default:
          break;
      }
    },
    update(){
      setTanVipPop(1)
    },
    userPopShow(){
      this.TanInfo = getTanLocalInfo()?.tjInfo || {}
      this.getVipInfo()
    },
    handlePhoneChange(e) {  // 手机号处理，只填入数字
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    closeWindow(){
      this.showService = false
    },
    service(index){
      switch (index) {
        case 0:
          this.showService = true
          // window.open('https://www.keqidao.com/yszc')
          break;
        case 1:
          this.showService = true
          // window.open('https://www.keqidao.com/yhxy')
          break;
        default:
          this.showService = true
          break;
      }
    },
    changeMessageCur(index){
      this.messageCur = index
    },
    openPersonalEditPop(){
      this.$store.dispatch('Tan/setPersonalEditShow',true)
    },
    openVip(){
      setTanVipPop(1)
    },
    showInvite(){
      this.$store.dispatch('Tan/setInviteShow',true)
    },
    setScroll(){  // 设置横向滚动
      this.scrollLeft = Math.max(
        document.body.scrollLeft,
        document.documentElement.scrollLeft
      );
      window.onscroll = () => {
        this.scrollLeft = Math.max(
          document.body.scrollLeft,
          document.documentElement.scrollLeft
        );
      };
    },
    toHomePage(){
      routerWindow(this.$router,'/',{})
    },
  },
}
</script>

<style lang="scss" scoped>
.header{
  position: fixed;
  width: 100%;
  height: 70px;
  left: 0;
  top: 0;
  min-width: 1210px;
  background: #FFFFFF;
  box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.06);
  z-index: 2001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 83px;
  padding-right: 31px;
  .left{
    display: flex;
    align-items: center;
    .logo{
      width: 92px;
      height: 50px;
      margin-right: 108px;
      cursor: pointer;
    }
    .p-0{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #111111;
      line-height: 28px;
      padding-right: 26px;
      border-right: 1px solid #EBEBEB;
      cursor: pointer;
      &:hover{
        color: $--color-theme;
      }
    }
    .p-1{
      display: flex;
      align-items: center;
      img{
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #111111;
      line-height: 28px;
      padding-right: 26px;
      padding-left: 26px;
      border-right: 1px solid #EBEBEB;
      cursor: pointer;
      &:hover{
        color: $--color-theme;
      }
    }
  }
  .right{
    display: flex;
    align-items: center;
    .update{
      width: 70px;
      height: 28px;
      background: #F2F8FF;
      border-radius: 16px;
      border: 1px solid $--color-theme;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      .rocket{
        width: 18px;
        height: 22px;
        margin-left: 10px;
        margin-right: 3px;
      }
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: $--color-theme;
        line-height: 16px;
      }
    }
    .pop-image{
      width: 22px;
      height: 22px;
      cursor: pointer;
      margin-left: 20px;
    }
    .pop-ticket{
      width: 40px;
      height: 40px;
      cursor: pointer;
      margin-right: 26px;
    }
    .my-avatar{
      width: 34px;
      height: 34px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.message-window{
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .title-line{
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    .option{
      box-sizing: border-box;
      padding: 13px 20px 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      cursor: pointer;
    }
    .active{
      color: $--color-theme !important;
      position: relative;
      &::after{
        position: absolute;
        bottom: -2px;
        left: 50%;
        margin-left: -15px;
        content: '';
        width: 30px;
        height: 2px;
        background: $--color-theme;
        border-radius: 1px;
      }
    }
  }
  .tip{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: $--color-theme;
    line-height: 17px;
    position: absolute;
    right: 18px;
    top: 16px;
    cursor: pointer;
  }
  .message-container{
    width: 100%;
    .empty{
      width: 100%;
      padding: 51px 0 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img{
        width: 192px;
        height: 114px;
        margin-bottom: 4px;
      }
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
.ticket-window{
  width: 100%;
  box-sizing: border-box;
  padding: 23px 18px 13px;
  background-color: #ffffff;
  .ticket-title-line{
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 3px;
    padding-bottom: 11px;
    .ticket-down{
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  }
  .ticket-title{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 16px;
  }
  .ticket-wrap{
    width: 100%;
    max-height: 330px;
    overflow-y: auto;
  }
  .ticket-line{
    width: 100%;
    box-sizing: border-box;
    margin: 14px 0;
    display: flex;
    align-items: center;
    height: 95px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .new-icon{
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
    }
    .left{
      width: 96px;
      height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      .t-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #EC3E37;
        line-height: 56px;
        padding-top: 4px;
        white-space: nowrap;
        span{
          font-size: 14px;
          font-weight: 500 !important;
        }
      }
      .t-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #EC3E37;
        line-height: 20px;
        white-space: nowrap;
        span{
          font-size: 14px;
          font-weight: 500 !important;
        }
      }
    }
    .divide{
      width: 1px;
      height: 82px;
    }
    .right{
      width: 240px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 14px;
      .button{
        width: 74px;
        height: 26px;
        background: #EC3E37;
        border-radius: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 26px;
        cursor: pointer;
        text-align: center;
        margin-left: 20px;
      }
      .g-red{
        background: #FF8F8F !important;
      }
      .gray{
        background: #C4C4C4 !important;
      }
    }
  }
  .type-0{
    background-image: url('../../../assets/images/Tan/navbar/type-0.png');
    .right{
      .info{
        max-width: 150px;
        .name{
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #8B4B07;
          line-height: 16px;
          padding-bottom: 10px;
        }
        .n-type{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #CE7B23;
          line-height: 12px;
          padding-bottom: 10px;
        }
        .time{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #CE7B23;
          line-height: 12px;
        }
      }
    }
  }
  .type-1{
    background-image: url('../../../assets/images/Tan/navbar/type-1.png');
    .right{
      .info{
        max-width: 150px;
        .name{
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #EC3E37;
          line-height: 16px;
          padding-bottom: 10px;
        }
        .n-type{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
          padding-bottom: 10px;
        }
        .time{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
        }
      }
    }
  }
  .type-2{
    background-image: url('../../../assets/images/Tan/navbar/type-2.png');
    .right{
      .info{
        max-width: 150px;
        .name{
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #BC7927;
          line-height: 16px;
          padding-bottom: 10px;
        }
        .n-type{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #CD8940;
          line-height: 12px;
          padding-bottom: 10px;
        }
        .time{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #CD8940;
          line-height: 12px;
        }
      }
    }
  }
  .ticket-tip{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    padding-top: 11px;
    margin-top: 12px;
    width: calc(100% + 36px);
    margin-left: -18px;
    padding-left: 18px;
    box-sizing: border-box;
    border-top: 1px solid #F7F7F7;
    cursor: pointer;
  }
}
.ticket-type-option-wrap{
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}
.ticket-type-option{
  width: 100%;
  box-sizing: border-box;
  padding: 7px 0;
  text-align: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  cursor: pointer;
}
.ticket-type-active{
  background: #FFF9EE;
}
.service-window{
  width: 100%;
  box-sizing: border-box;
  padding: 10px 13px 10px 20px;
  .line{
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    &:hover{
      p{
        color: $--color-theme !important;
      }
    }
    .icon{
      width: 16px;
      height: 16px;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      width: calc(100% - 40px);
    }
    .right{
      width: 12px;
      height: 12px;
    }
  }
}
.cor-window{
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 52px;
  .p-0{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 21px 23px 13px;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 25px;
  }
  .icon{
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 23px;
  }
  .form-item{
    width: 457px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    .p-1{
      width: 80px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: right;
      span{
        color: #EA0D0D;
      }
    }
    .name{
      width: 376px;
      height: 32px;
      background: #EFF4F9;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      box-sizing: border-box;
      padding: 4px 9px;
    }
    .input{
      width: 376px;
    }
    .right{
      width: 376px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .active{
        border: 1px solid $--color-theme !important;
        color: $--color-theme !important;
      }
      .way{
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid #C7C7C7;
        box-sizing: border-box;
        padding: 8px 9px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        margin-bottom: 14px;
      }
    }
  }
  .form-item-textarea{
    width: 457px;
    padding: 10px 0;
    .p-1{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-bottom: 12px;
      span{
        color: #EA0D0D;
      }
    }
    .textarea{
      width: 457px;
      height: 110px;
      border-radius: 2px;
      border: 1px solid #C7C7C7;
      box-sizing: border-box;
      padding: 8px 9px;
      font-size: 14px;
      color: 333333;
      resize: none;
    }
  }
  .buttons{
    margin-top: 37px;
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel{
      width: 130px;
      height: 32px;
      background: #C6CAD3;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin: 0 15px;
    }
    .confirm{
      margin: 0 15px;
      width: 130px;
      height: 32px;
      background: $--color-theme;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
.user-window{
  width: 100%;
  box-sizing: border-box;
  padding: 23px;
  background-color: #ffffff;
  .update{
    display: flex;
    align-items: center;
    height: 22px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    color: $--color-theme;
    img{
      width: 14px;
      height: 12px;
      margin-top: 2px;
    }
  }
  .top{
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    .avatar{
      width: 44px;
      height: 44px;
      margin-right: 8px;
    }
    .right{
      width: calc(100% - 52px);
      .name{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 16px;
        width: 100%;
        margin-bottom: 8px;
      }
      .phone{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 12px;
        width: 100%;
      }
    }
  }
  .company-info{
    background: linear-gradient( 90deg, #F3F8FF 0%, #F5F0FF 100%);
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 13px;
    .name{
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
      margin-bottom: 13px;
    }
    .num{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
      width: 100%;
      margin-bottom: 18px;
    }
    .line{
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .button{
      padding-top: 10px;
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding-bottom: 10px;
      &:hover{
        p{
          color: $--color-theme !important;
        }
      }
      .icon{
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 12px;
      }
    }
  }
  .bottom{
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      width: 93px;
      height: 28px;
      background: #E5EFFA;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #5481BD;
      border: none;
      cursor: pointer;
    }
  }
}
.ticket-trial-dialog{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 0 32px;
  .ticket-exchange-input{
    width: 260px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    margin-top: 30px;
    font-size: 14px;
    color: #333333;
    box-sizing: border-box;
    padding: 0 15px;
  }
  .ticket-dialog-name{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 16px;
  }
  .ticket-dialog-time{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 23px;
    padding-top: 17px;
    text-align: center;
    span{
      color: #EC3E37;
    }
  }
  .buttons{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 23px;
    .cancel{
      width: 94px;
      height: 28px;
      background: #C6CAD3;
      cursor: pointer;
      border: none;
      border-radius: 2px;
      margin: 0 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
    .confirm{
      width: 94px;
      height: 28px;
      background: $--color-theme;
      cursor: pointer;
      border: none;
      border-radius: 2px;
      margin: 0 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
</style>