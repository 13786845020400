import { post } from "@/utils/request";
import { getTanId } from "@/utils/Tan/login";

// 探角优惠劵领取-兑换码兑换(批量领取)
export function receiveTanTicket(arr) {
  return post('/tj/coupon/batchReceive', {
    memberId: getTanId(),
    couponCodeList: arr,
  })
}

// 探角优惠劵已领取列表
export function getTanCompanyTicketList(param) {
  return post('/tj/coupon/myCouponList', {
    memberId: getTanId(),
    ...param,
  })
}

// 探角优惠劵可点击领取列表(已领取不显示)
export function getTanAllTicketList() {
  return post('/tj/coupon/receiveList', {
    memberId: getTanId(),
  })
}

// 探角试用优惠劵使用
export function useTanTicketByCode(code) {
  return post('/tj/coupon/useCoupon', {
    couponCode: code,
    memberId: getTanId(),
  })
}

